<template>
  <div id="wrapper">
    <SidebarView :users="true" :permissions="true" :user-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <b-container>
          <div class="">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="card card-shape home-box">
                  <div class="card-header py-3 d-flex flex-row align-items-center">
                    <h6 class="m-0 fw-bold text-green">Users Roles</h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-9">
                        <multiselect v-model="form.selected_role" name="selected_role" placeholder="Select A User Role"
                          label="display_name" track-by="id" :options="roles" @select="roleSelected"></multiselect>
                      </div>
                      <div class="col-md-3">
                        <roles-modal @saveRole="saveRole"></roles-modal>
                        <!--Roles Modal End-->
                        <!-- <a class="btn btn-success">Add Role</a> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="card card-shape home-box">
                  <div class="card-header py-3 d-flex flex-row align-items-center">
                    <h6 class="m-0 fw-bold text-green">Permissions</h6>
                  </div>
                  <div class="card-body">
                    <user-permissions-table :isLoading="isLoading" @permissionChecked="permissionChecked" :form="form"
                      :errors="errors" :permissions="permissions" @updatePermissionsNow="updatePermissionsNow"
                      :is_Updating="is_Updating"></user-permissions-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </div>
      <Footer></Footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>


<script>
import Multiselect from "vue-multiselect";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
// import { Form } from "vform";
import UserPermissionsTable from "./tables/UserPermissionsTable.vue";
import ApiService from "@/core/services/api.service";

import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import RolesModal from "./forms/RolesModalForm.vue";
export default {
  mounted() {
    this.getUserOptions();
  },
  components: {
    Multiselect,
    UserPermissionsTable,
    SidebarView,
    TopbarView,
    Footer,
    RolesModal,
    BeatLoaderComponent,
  },
  data() {
    return {
      form: {
        id: "",
        selected_role: "",
        display_name: "",
        nameState: null,
      },
      errors: [],
      isLoading: true,
      roles: [],
      permissions: [],
      permissions_checked: [],
      is_Updating: false,
    };
  },
  methods: {
    async getUserOptions() {
      this.$Progress.start();
      this.isLoading = true;

      await ApiService.get("/roles/dropdown")
        .then((response) => {
          console.log(response.data);
          this.roles = response.data.roles;
          // this.form.selected_role = this.roles[0];
          this.isLoading = false;
          // if (this.roles != null) {
          //   this.roleSelected(this.form.selected_role);
          // }

          this.$Progress.finish();
        })
        .catch((error) => { });
    },

    // initialState() {
    //   return {
    //     form: {
    //       display_name: "",
    //       nameState: "",
    //     },
    //     isLoading: false,
    //     isSaving: false,
    //   };
    // },
    async roleSelected(role) {
      this.isLoading = true;
      console.log(role)
      if (role != undefined) {
        await ApiService.get("/users/permissions/getPermissions/" + role.id)
          .then((response) => {
            this.permissions = response.data.permissions;
            var role_permissions = response.data.role_permissions;
            console.log(response);
            // console.log(role_permissions.length);
            if (role_permissions.length > 0) {
              this.permissions.forEach((permii, indexii) => {
                permii.permissions.forEach((pmission, pmission_index) => {
                  if (role_permissions.includes(pmission.id)) {
                    this.permissions[indexii].permissions[
                      pmission_index
                    ].is_checked = true;
                  } else {
                    this.permissions[indexii].permissions[
                      pmission_index
                    ].is_checked = false;
                  }
                });
              });
            }

            this.isLoading = false;
            this.$Progress.finish();
          })
          .catch((error) => {
            this.isLoading = false;
          });
      }

    },
    async saveRole(form) {
      this.isLoading = true;
      this.isSaving = true;
      this.$Progress.start();
      await ApiService.post("roles/store", form)
        .then((response) => {

          this.$bvModal.hide("roles-modal");
          this.$Progress.finish();
          this.isSaving = false;
          //   this.form = this.initialState().form;
          this.getUserOptions();
          this.isLoading = false;
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          // this.resetForm();
          this.errors = [];
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.errors = error.response.data.errors;
          }
        });

    },
    permissionChecked(permission_id, truth_value) {
      // if this permission is marked as true but permission isn't already in permissions checked array then add it
      if (truth_value && truth_value == true) {
        if (!this.permissions_checked.includes(permission_id)) {
          this.permissions_checked.push(permission_id);
        }
      }

      //permission was in array then delete it
      if (truth_value && truth_value == false) {
        if (this.permissions_checked.includes(permission_id)) {
          this.permissions_checked.splice(
            this.permissions_checked.indexOf(permission_id),
            1
          );
        }
      }
    },
    async updatePermissionsNow() {
      this.is_Updating = true;
      this.$Progress.start();
      this.permissions_checked = [];
      this.permissions.forEach((modules, index) => {
        this.permissions[index].permissions.forEach(
          (permission, permission_index) => {
            if (permission.is_checked && permission.is_checked == true) {
              // console.log(permission.name + " " + permission.is_checked);
              this.permissions_checked.push(permission.id);
            }
          }
        );
      });

      //submit the permissions to update them
      await ApiService.post(
        "/users/permissions/updatePermissions/" + this.form.selected_role.id,
        {
          permissions_checked: this.permissions_checked,
        }
      )
        .then((response) => {
          // console.log(response.data);
          this.$Progress.finish();
          this.is_Updating = false;
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
        })
        .catch((error) => {
          this.is_Updating = false;
        });
    },
  },
};
</script>
