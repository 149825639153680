<template>
    <div>
        <b-button v-b-modal="String('fixed-deposit')" class="btn btn-sm akkurate-green-btn" href="" variant="primary">
            <span class="akkurate-small"> <i class="fas fa-plus"></i> Add New</span>
        </b-button>
        <b-modal @ok="handleOk" :id="String('fixed-deposit')" size="xxl" @shown="depositFixed">
            <template #modal-title>
                <h6 class="m-0 fw-bold text-green">Create Fixed Deposit</h6>
            </template>
            <div class="container">
                
                <beat-loader-component v-if="isLoading" :isLoading="isLoading"></beat-loader-component>
                <fd-forms v-else :form="form" :isLoading="isLoading"
                    :fixed_deposit_scheme_options="fixed_deposit_scheme_options"
                    :approval_options="approval_options"></fd-forms>
                    
                </div>
            <template #modal-footer="{ ok }">
                <div class="w-100">
                    <div class="akkurate-danger text-center small fst-italic">{{error_msg}}</div>
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="ok()" class="akkurate-green-btn btn-size me-2 float-right border-0">
                        <font-awesome-icon class="me-2" :icon="['fas', 'save']" :disabled="isSaving" />Forward</b-button>

                    <b-button @click="$bvModal.hide(String('fixed-deposit'))" type="submit"
                        class="akkurate-grey me-2 btn-size  float-right"><font-awesome-icon class="me-2"
                            :icon="['fas', 'close']" />Close</b-button>
                </div>
            </template>

        </b-modal>
    </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
import FdForms from "../form/FdForms.vue";
export default {
    components: {
        BeatLoaderComponent,
        FdForms
    },
    data() {
        return {
            ...this.initialState(),
            isLoading: false,
            fixed_deposit_scheme_options: [],
            isSaving: false,
            approval_options:[],
            error_msg:"",
        }
    },

    methods: {
        async getLoanView() {
            this.isLoading = true;
            this.isBusy = true,
                await ApiService.get(`/accounts/view/${this.data.account_number}`)
                    .then((response) => {
                        this.isLoading = false;
                        this.isBusy = false;
                        this.form = response.data.account;
                        this.handleImageLoad();
                        // this.loan = response.data.data;
                        // console.log(response.data.data);
                        // this.col_items = response.data.data.collaterals;
                        // this.gua_items = response.data.data.guarantors;
                    }).catch((error) => {
                        this.isLoading = false;
                        this.isBusy = false;
                        // console.log(error);
                    });
        },
        showView() {
            // console.log(this.data);
            this.getLoanView();
        },
        handleImageLoad() {

            if (this.form.customer?.gh_card_front_path) {
                this.imageLoaded = true;
                this.frontImgUrl = this.form.customer?.gh_card_front_path;
            }
            if (this.form.customer?.gh_card_back_path) {
                this.imageLoaded = true;
                this.backImgUrl = this.form.customer?.gh_card_back_path;
            }


        },
        getDropdown() {
            this.isLoading = true
            ApiService.get('investments/fixed-term-deposits/dropdown')
                .then((response) => {
                    this.fixed_deposit_scheme_options = response.data.fd_scheme
                }).catch((errors) => {
                    console.log(errors)
                }).finally(() => {
                    this.isLoading = false
                })
        },
        depositFixed() {
            this.getDropdown();
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.form.fixed_deposit_scheme_id = this.form.fixed_deposit_scheme_id.id;
            this.isSaving = true;
            this.$Progress.start();
            ApiService.post("investments/fixed-term-deposits", this.form)
                .then((response) => {
                    console.log(response);
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    this.form = this.initialState().form;
                    this.$Progress.finish();
                    this.$emit('saveFD');
                    this.$bvModal.hide(String('fixed-deposit'));

                }).catch((error) => {
                    // console.log(error.response.data.message);
                    this.isSaving = false;
                    this.$Progress.fail();
                    if(error.response?.data?.message){
                        this.error_msg = error.response?.data?.message
                    }
                    if (error.response?.data?.errors) {
                        this.form.errors = error.response?.data?.errors;
                    }
                });
        },
        initialState() {
            return {
                form: {
                    principal_amount: "",
                    fixed_deposit_scheme_id: "",
                    start_date: "",
                    interest_rate: "",
                    payment_mode: "",
                    errors: [],
                    denominations: [
                        { denom_amount: '1', denom_frequency: '', sub_total: '' }
                    ],
                    customer:{},
                    fixed_deposit_mandate:"",
                    fixed_deposit_approval_id:1,
                    cheque_number:"",
                },
            }
        }
    }
}
</script>
