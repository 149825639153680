<template>

    <div class="card trans_details mt-3">
        <div class="card-header">
            <h6 class="m-0 fw-bold text-green">Loan Topup</h6>
        </div>

        <div class="card-body">
            <form @submit.prevent="storeTopup" class="mb-3">
                <div class="row mt-3">
                    <div class="col-md-6">
                        <label class="akkurate-dark" for="topup_amount">Topup Amount <star></star></label>
                        <b-form-group id="topup_amount" label-for="topup_amount">
                            <b-form-input name="topup_amount" class="mt-2 field-container fields" type="text"
                                placeholder="Enter Amount" v-model="form.topup_amount" aria-describedby="topup_amount"
                                required></b-form-input>
                            <b-form-invalid-feedback>{{ veeErrors.first('topup_amount')
                                }}</b-form-invalid-feedback>
                            <errors v-if="form.errors.topup_amount">
                                {{ form.errors.topup_amount[0] }}
                            </errors>
                        </b-form-group>
                    </div>
                    <div class="col-md-6">
                        <b-form-group label-for="reason" class="mb-2">
                            <template #label>
                                <label class="akkurate-dark" for="reason">Reason <star></star></label>
                            </template>
                            <b-form-textarea class="mt-2 fields" id="reason" name="reason" v-model="form.reason"
                                placeholder="Enter Reason..." aria-describedby="reason" data-vv-as="Reason"
                                required></b-form-textarea>
                            <b-form-invalid-feedback>{{ veeErrors.first('reason')
                                }}</b-form-invalid-feedback>
                            <errors v-if="form.errors.reason">
                                {{ form.errors.reason[0] }}
                            </errors>
                        </b-form-group>
                    </div>
                    <hr />
                    <div class="col-12 text-end">
                        <b-button @click="clearForm" class="akkurate-gray-btn me-2 btn-size"><font-awesome-icon
                                class="me-2" :icon="['fas', 'close']" />Clear</b-button>
                        <button type="submit" :disabled="isSaving"
                            class="btn akkurate-green-btn text-white btn-size me-2"><font-awesome-icon class="me-2"
                                :icon="['fas', 'save']" />{{ save }}</button>

                    </div>
                </div>
            </form>
            <vue-good-table styleClass="vgt-table bordered table-dropdown striped" mode="remote" ref="loanTopupTable"
                :columns="columns" :rows="rows" :isLoading.sync="isLoading">
                <template slot="table-row" slot-scope="props">
                    <span class="d-flex" v-if="props.column?.field == 'action'">
                        <!-- Branch Manager Action -->
                        <div class="d-flex"
                            v-if="user_role === 'Branch Manager' && props.row.created_by != current_user_id && props.row.status != 'RUNNING' && props.row.status != 'DISBURSED' && props.row.status != 'REJECTED'">
                            <a v-if="props.row.loan_approval_level?.name === 'Awaiting Branch Manager Approval'"
                                title="Approve" class="btn btn-sm akkurate-green-btn text-white border-0"
                                @click="approveLoanTopup(props.row.loan_number)">
                                <span class="akkurate-small">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </span>
                            </a>
                            <a v-if="props.row.loan_approval_level?.name === 'Awaiting Branch Manager Approval'"
                                title="Reject" class="btn btn-sm akkurate-warning text-white border-0"
                                @click="rejectLoanTopup(props.row.loan_number)">
                                <span class="akkurate-small">
                                    <font-awesome-icon :icon="['fas', 'close']" />
                                </span>
                            </a>
                        </div>
                        <!-- When the user is a Loan Officer -->
                        <div class="d-flex"
                            v-if="user_role === 'Loan Officer' || user_role === 'Loan Committee Chairman' && props.row.status != 'RUNNING' && props.row.status != 'DISBURSED' && props.row.status != 'REJECTED'">
                            <a v-if="props.row.loan_approval_level?.name === 'Awaiting Loan Officer Approval' || props.row.loan_approval_level?.name === 'Awaiting Loan Chairman Approval'"
                                title="Approve" class="btn btn-sm akkurate-green-btn text-white border-0"
                                @click="approveLoanTopup(props.row.loan_number)">
                                <span class="akkurate-small">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </span>
                            </a>
                            <a v-if="props.row.loan_approval_level?.name === 'Awaiting Loan Officer Approval'"
                                title="Reject" class="btn btn-sm akkurate-blue text-white border-0"
                                @click="reverseTopup(props.row.loan_number)">
                                <span class="akkurate-small">
                                    <font-awesome-icon :icon="['fas', 'backward']" />
                                </span>
                            </a>
                            <a v-if="props.row.loan_approval_level?.name === 'Awaiting Loan Officer Approval' || props.row.loan_approval_level?.name === 'Awaiting Loan Chairman Approval'"
                                title="Reject" class="btn btn-sm akkurate-warning text-white border-0"
                                @click="rejectLoanTopup(props.row.loan_number)">
                                <span class="akkurate-small">
                                    <font-awesome-icon :icon="['fas', 'close']" />
                                </span>
                            </a>
                        </div>
                        <!-- When the user is a Accountant -->
                        <div class="d-flex"
                            v-if="user_role === 'Accountant' && props.row.status != 'RUNNING' && props.row.status != 'DISBURSED' && props.row.status != 'REJECTED'">
                            <a v-if="props.row.loan_approval_level?.name === 'Awaiting Disbursement'"
                                title="Approve" class="btn btn-sm akkurate-green-btn text-white border-0"
                                v-b-modal="disbursement.id + 'disbursement'">
                                <span class="akkurate-small">
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </span>
                            </a>
                            <a v-if="props.row.loan_approval_level?.name === 'Awaiting Disbursement'"
                                title="Reject" class="btn btn-sm akkurate-blue text-white border-0"
                                @click="reverseTopup(props.row.loan_number)">
                                <span class="akkurate-small">
                                    <font-awesome-icon :icon="['fas', 'backward']" />
                                </span>
                            </a>
                            <a v-if="props.row.loan_approval_level?.name === 'Awaiting Disbursement'"
                                title="Reject" class="btn btn-sm akkurate-warning text-white border-0"
                                @click="rejectLoanTopup(props.row.loan_number)">
                                <span class="akkurate-small">
                                    <font-awesome-icon :icon="['fas', 'close']" />
                                </span>
                            </a>
                            <topup-disbursement :loan_account="props.row" :institution="institution"
                            :data="disbursement" :disbursement="'disbursement'"></topup-disbursement>
                        </div>
                        <!-- delete -->
                        <a v-if="props.row.status != 'RUNNING' && props.row.status != 'DISBURSED' && props.row.status != 'REJECTED'" title="Delete" class="btn btn-sm akkurate-red-btn text-end text-white"
                            @click="deleteTopup(props.row.loan_number)" variant="primary">
                            <span class="akkurate-small"> <i class="fas fa-trash"></i></span>
                    </a>

                    </span>
                    <span v-else-if="props.column?.field == 'prev_principal'">
                        <div>{{ Number(props.row.amount) - Number(props.row.principal_amount) }}</div>
                    </span>


                </template>
            </vue-good-table>
        </div>
    </div>

</template>
<script>
import ApiService from "@/core/services/api.service";
import TopupDisbursement from "../../disbursement/TopupDisbursement.vue";
export default {
    components: {
        TopupDisbursement
    },
    props: ['loan_number', 'active_tab_index', 'user_role', 'institution', 'current_user_id'],
    data() {
        return {
            ...this.initialState(),
            isLoading: false,
            filter: {
                loan_code: "",
            },
            search: "Search",
            save: "Save",
            isSearching: false,
            isSaving: false,
            total: "",
            columns: [
                {
                    label: '#',
                    field: 'id',
                },
                {
                    label: 'Expected Interest',
                    field: 'expected_interest',
                },
                {
                    label: 'Principal',
                    field: 'principal_amount',
                },
                {
                    label: 'Start date',
                    field: 'interest_start_date',
                },
                {
                    label: 'Status',
                    field: 'status',
                },
                {
                    label: 'Approval level',
                    field: 'loan_approval_level.name',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            loan_stage: 'Awaiting Branch Manager Approval',
            disbursement: {
                id: 'loan_disbursement'
            },
        }
    },
    watch: {
        active_tab_index(event) {
            if (event === 5) {
                // console.log(this.loan_number)
                this.filterLoan(this.loan_number);
            }
        }
    },
    methods: {
        validateState(ref) {
            if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
                return !this.veeErrors.has(ref);
            }
            return null;
        },
        // handleOk(bvModalEvent) {
        //     // Prevent modal from closing
        //     bvModalEvent.preventDefault();
        //     // Trigger submit handler
        //     this.handleSubmit();
        // },
        // async handleSubmit() {
        //     this.$validator.validateAll().then(result => {
        //         if (!result) {
        //             return;
        //         }
        //         this.storeTopup();

        //     });

        // },

        async storeTopup() {
            this.isSaving = true;
            this.$Progress.start();
            // this.form.loan_number = this.filter.loan_code;
            this.form.loan_number = this.loan_number
            await ApiService.post("/loans/loanTopUp", this.form)
                .then((response) => {
                    this.isLoadingSaving = false;
                    this.$Progress.finish();
                    toast.fire({
                        icon: "success",
                        title: response.data.message,
                    });
                    this.isSaving = false;
                    // this.form.reset();
                    this.form = this.initialState().form;
                    this.filterLoan(this.loan_number)
                })
                .catch((error) => {
                    this.isSaving = false;
                    this.$Progress.fail();

                    if (error.response.data.errors) {
                        this.form.errors = error.response.data.errors;
                    }
                    if (error.response.status == 400) {
                        swal.fire({
                            icon: "error",
                            title: error.response?.statusText,
                            text: error.response?.data?.message,
                            showCancelButton: false, // Show the "Cancel" button
                            confirmButtonText: 'OK', // Customize the text for the "OK" button
                            buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                            customClass: {
                                confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                            }
                            // footer: '<a href="#">Why do I have this issue?</a>'
                        });
                    }
                });
        },

        async filterLoan(filter = null) {
            this.search = "processing...";
            this.isSearching = true;
            this.isLoading = true;
            await ApiService.get(`/loans/topupByLoanNumber/${filter}`)
                .then((response) => {
                    console.log(response.data.data)
                    this.rows = response.data.data
                    //     let the_status = response?.data?.data?.status;
                    //     if (the_status == "RUNNING" || the_status == "EXPIRED" || the_status == "CLOSED" || the_status == "REJECTED" || the_status == "DISBURSED") {
                    //      this.canUpdateStatus = false
                    //   } else {
                    //      this.canUpdateStatus = true
                    //   }
                    // this.loan = response.data.data;
                    // this.search = "Search";
                    // this.isSearching = false;
                    // this.isLoading = false;
                }).catch((error) => {
                    this.search = "Search";
                    this.isSearching = false;
                    this.isLoading = false;
                    // if (error.response.status == 404) {
                    //     swal.fire({
                    //         icon: "error",
                    //         title: error.response.statusText,
                    //         text: "Something went wrong!",
                    //         showCancelButton: false, // Show the "Cancel" button
                    //         confirmButtonText: 'OK', // Customize the text for the "OK" button
                    //         buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                    //         customClass: {
                    //             confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                    //         }
                    //         // footer: '<a href="#">Why do I have this issue?</a>'
                    //     });
                    // }
                })
        },
        approveLoanTopup(filter) {
            swal.fire({
                title: "Approve Loan Topup",
                text: 'Are you sure you want to approve loan number ' + filter + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }

            }).then(result => {
                if (result.value) {
                    this.$Progress.start();
                    ApiService.post('/loans/loanTopUp/approveLoanTopup', { 'loan_number': filter })
                        .then((response) => {
                            this.$Progress.finish();
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.filterLoan(filter);
                        }).catch((error) => {
                            this.$Progress.fail();
                            swal.fire({
                                // title: response.statusText,
                                text: error.response?.data?.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });
        },
        reverseTopup(filter = null) {
            swal.fire({
                title: "Reverse Loan Topup",
                text: 'Are you sure you want to reverse loan number ' + filter + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }

            }).then(result => {
                if (result.value) {
                    this.$Progress.start();
                    ApiService.post('/loans/loanTopUp/reverseTopupApproval', { 'loan_number': filter })
                        .then((response) => {
                            this.$Progress.finish();
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.filterLoan(filter);
                        }).catch((error) => {
                            this.$Progress.fail();
                            swal.fire({
                                // title: response.statusText,
                                text: error.response?.data?.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });
        
        },
        rejectLoanTopup(filter = null) {
            swal.fire({
                title: "Reject Loan Topup",
                text: 'Are you sure you want to reject loan number ' + filter + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }

            }).then(result => {
                if (result.value) {
                    this.$Progress.start();
                    ApiService.post('/loans/loanTopUp/rejectTopupApproval', { 'loan_number': filter })
                        .then((response) => {
                            this.$Progress.finish();
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.filterLoan(filter);
                        }).catch((error) => {
                            this.$Progress.fail();
                            swal.fire({
                                // title: response.statusText,
                                text: error.response?.data?.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });
        },

        deleteTopup(filter = null){
            swal.fire({
                title: "Delete Loan Topup",
                text: 'Are you sure you want to delete loan number ' + filter + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }

            }).then(result => {
                if (result.value) {
                    this.$Progress.start();
                    ApiService.post('/loans/loanTopUp/deleteTopupApproval', { 'loan_number': filter })
                        .then((response) => {
                            this.$Progress.finish();
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.filterLoan(filter);
                        }).catch((error) => {
                            this.$Progress.fail();
                            swal.fire({
                                // title: response.statusText,
                                text: error.response?.data?.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });
        },

        clearForm() {
            this.form = this.initialState().form; // Call the initialState method to get the default form
        },
        initialState() {
            return {
                form: {
                    loan_number: "",
                    topup_amount: "",
                    errors: [],
                    source: "web"
                },
            }
        },
    },
}
</script>