<template>
    <div class="card card-shape home-box">
        <div class="card-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <h6 class="m-0 fw-bold text-green me-4">Fixed Deposit Entries</h6>
                    <p class="mb-0 akkurate-small me-2 akkurate-orange-text">
                        <font-awesome-icon :icon="['fas', 'circle']" />
                    </p>
                    <span class="small me-3">PP(Principal Paid)</span>
                    <p class="mb-0 akkurate-small me-2 akkurate-danger">
                        <font-awesome-icon :icon="['fas', 'circle']" />
                    </p>
                    <span class="small me-3">PB(Principal Balance)</span>
                    <p class="mb-0 akkurate-small me-2 akkurate-green">
                        <font-awesome-icon :icon="['fas', 'circle']" />
                    </p>
                    <span class="small me-3">IP(Interest Paid)</span>
                    <p class="mb-0 akkurate-small me-2 akkurate-blue-text">
                        <font-awesome-icon :icon="['fas', 'circle']" />
                    </p>
                    <span class="small me-3">IB(Interest Balance)</span>
                    <p class="mb-0 akkurate-small me-2 akkurate-purple">
                        <font-awesome-icon :icon="['fas', 'circle']" />
                    </p>
                    <span class="small me-3">ID(Interest Due)</span>
                </div>
                <div>
                    <fd-filter :type="'entries'" :adv_filter="adv_filter" :isSearching="isSearching" :search="search"
                        @filterFixedDeposit="filterFixedDeposit"></fd-filter>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="container">
                <div class="row trans_details">
                    <b-table outlined responsive :fields="entries_fields" :items="entries_items">
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>

                        <template #cell(debit)="data">
                            <p class="mb-0" v-if="data?.item?.transaction_type == 'Fixed Term Withdrawal'">{{
                                displayNumber(data?.item?.trans_amount) }}</p>
                        </template>

                        <template #cell(credit)="data">
                            <p class="mb-0" v-if="data?.item?.transaction_type == 'Fixed Term Deposit'">{{
                                displayNumber(data?.item?.trans_amount) }}</p>
                        </template>

                        <template #cell(description)="data">
                            <p class="mb-0">
                                {{ data.item.transaction_type }} By
                                {{ data.item.transaction_mode }}
                            </p>
                        </template>


                        <template #cell(interest)="data">
                            <span> {{ displayNumber(data.item.interest) }} </span>
                        </template>

                        <!-- <template #cell(action)="data">
                            <div class="d-flex">
                                <a title="Receipts" class="btn btn-sm akkurate-green-btn text-white"
                                    @click="printReceipt(data)">
                                    <span class="akkurate-small">
                                        <font-awesome-icon :icon="['fas', 'book']" />
                                    </span>
                                </a>
                                <div title="Cheque" class="dropdown no-arrow">
                                    <a title="Cheque" class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle"
                                        id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <span class="akkurate-small">
                                            <font-awesome-icon :icon="['fas', 'money-bill']" />
                                        </span>
                                    </a>
                                    <div class="dropdown-menu shadow animated--fade-in" aria-labelledby="dropdownMenu">
                                        <div class="dropdown-header">Statments</div>
                                        <button class="dropdown-item" @click="chequePrintout(data)">Cheque
                                            Printout</button>
                                        <button class="dropdown-item" @click="chequeTemplate(data)">Cheque
                                            Template</button>
                                    </div>
                                </div>
                                <a title="Reverse" class="btn btn-sm akkurate-danger-btn text-white"
                                    @click="loanReverse(data)">
                                    <span class="akkurate-small"><font-awesome-icon
                                            :icon="['fas', 'exchange-alt']" /></span>
                                </a>
                            </div>
                        </template> -->

                        <template #custom-foot>
                            <tr>
                                <td class="fw-bold">Total</td>
                                <td class="fw-bold"></td>
                                <td class="fw-bold">{{ displayNumber(total_credit) }}</td>
                                <td class="fw-bold">{{ displayNumber(total_debit) }}</td>
                                <td class="fw-bold"></td>
                                <td class="fw-bold"></td>
                                <td class="fw-bold"></td>
                                <td class="fw-bold">{{ displayNumber(total_principal) }}</td>
                                <td class="fw-bold"></td>
                                <td class="fw-bold"></td>
                                <td class="fw-bold">{{ displayNumber(total_interest) }}</td>
                                <td class="fw-bold"></td>
                            </tr>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ApiService from "@/core/services/api.service";
import FdFilter from "./FdFilter.vue";
export default {
    props: ['fixed_deposit'],
    components: {
        FdFilter
    },
    data() {
        return {
            adv_filter: {
                start_date: this.getOneMonthBeforeDate(),
                end_date: new Date().toISOString().split('T')[0],
            },
            isSearching: false,
            search: "Search",
            reportData: [],
            entries_fields: [
                {
                    key: 'trans_date',
                    label: 'Date',
                },

                {
                    key: 'description',
                    label: 'Description',
                },
                {
                    key: 'debit',
                    label: 'Debit',
                },
                {
                    key: 'credit',
                    label: 'Credit',
                },

                {
                    key: 'principal',
                    label: 'Principal',
                },
                {
                    key: 'interest',
                    label: 'Interest',
                },
                {
                    key: 'principal_paid',
                    label: 'PP',
                },
                {
                    key: 'principal_balance',
                    label: 'PB',
                },
                {
                    key: 'interest_paid',
                    label: 'IP',
                },
                {
                    key: 'interest_balance',
                    label: 'IB',
                },
                {
                    key: "action",
                    label: "Action",
                },
            ],
            entries_items: [],
        }
    },
    computed: {
        getFullName() {
            const first_name = this.loan.account.customer.first_name != undefined ? this.loan.account.customer.first_name : "";
            const middle_name = this.loan.account.customer.middle_name != undefined ? this.loan.account.customer.middle_name : "";
            const last_name = this.loan.account.customer.last_name != undefined ? this.loan.account.customer.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;

        },
        getBranch() {
            const branch = this.loan?.account?.customer?.branch?.name ? this.loan?.account?.customer?.branch?.name : ""
            return branch
        },
        total_credit() {
            return this.entries_items
                .filter((trans) => trans.transaction_type == "Loan Repayment")
                .reduce((total, trans) => total + trans.trans_amount, 0);
        },
        total_debit() {
            return this.entries_items
                .filter((trans) => trans.transaction_type != "Loan Repayment")
                .reduce((total, trans) => total + trans.trans_amount, 0);
        },
        total_principal() {
            return (
                this.entries_items
                    // .filter(trans => trans.transaction_type != 'Loan Repayment')
                    .reduce((total, trans) => total + trans.principal, 0)
            );
        },
        total_interest() {
            return this.entries_items.reduce((total, trans) => total + trans.interest, 0);
        },
    },
    mounted() {
        console.log('code', this.fixed_deposit)
        if (this.fixed_deposit?.code) {
            this.filterFixedDepositEntries();
        }
    },
    methods: {
        filterFixedDeposit() {
            this.filterFixedDepositEntries();
        },
        filterFixedDepositEntries() {
            // console.log(this.filter.start_date);
            // console.log(this.loan.loan_number)
            this.search = "processing...";
            this.isSearching = true;
            ApiService.query("/investments/fixedDepositEntries", {
                params: {
                    fd_code: this.fixed_deposit?.code,
                    start_date: this.adv_filter?.start_date,
                    end_date: this.adv_filter?.end_date,
                }
            }).then((response) => {
                // console.log(response.data.data);
                this.details = response.data.data;
                this.isLoading = false;
                this.statement_items = response.data.data.account.transactions.map((index) => {
                    return {
                        id: index.id,
                        trans_date: index.trans_date,
                        transaction_type: index.transaction_type,
                        transaction_mode: index.transaction_mode,
                        trans_amount: index.trans_amount,
                        debit: Math.round(index.debit * 100) / 100,
                        credit: Math.round(index.credit * 100) / 100,
                        principal: Math.round(index.principal * 100) / 100,
                        principal_paid: Math.round(index.principal_paid * 100) / 100,
                        principal_balance: Math.round(index.principal_balance * 100) / 100,
                        interest_paid: Math.round(index.interest_paid * 100) / 100,
                        interest_balance: Math.round(index.interest_balance * 100) / 100,
                        interest: index.interest,
                        denominations: index.denominations,
                        loan_interest_due: index.loan_interest_due,
                        loan_principal_due: index.loan_principal_due,
                        cheque_number: index.cheque_number,
                        code: index.code
                    }
                });
                this.entries_items = this.statement_items
                // this.handleImageLoad();
                this.search = "Search";
                this.isSearching = false;
            }).catch((error) => {
                this.search = "Search";
                this.isSearching = false;
                console.log(error)
                if (error.response.status == 404) {
                    swal.fire({
                        icon: "error",
                        title: error.response.statusText,
                        text: "Something went wrong!",
                        showCancelButton: false, // Show the "Cancel" button
                        confirmButtonText: 'OK', // Customize the text for the "OK" button
                        buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                        customClass: {
                            confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                        }
                        // footer: '<a href="#">Why do I have this issue?</a>'
                    });
                }
            })
        },
        getOneMonthBeforeDate() {
            // Get today's date
            let currentDate = new Date();

            // Remove one month to the current date
            currentDate.setMonth(currentDate.getMonth() - 1);

            // Format the date as 'YYYY-MM-DD'
            return currentDate.toISOString().substr(0, 10);
        },
    }

}
</script>