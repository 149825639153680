<template>
     <div>
        <a v-b-modal="String(data.id + 'certificate')" class="dropdown-item" @click.prevent href=""><font-awesome-icon class="akkurate-green" :icon="['fas', 'certificate']"  /></a>
        <b-modal 
            :id="String(data.id + 'certificate')"
            size="xl"
            @shown="modalShown"
            @ok="">
            <template #modal-title>
                <div class="fw-bold akkurate-green">Certificate</div>
            </template>
            <div class="container-fluid certificate-bg" ref="pdfContent">
                <div class="cert-padding">
                    <div class="row mb-3">
                        <div class="col-md-4">
                            <logo></logo>
                        </div>
                        <div class="col-md-8 text-end">
                            <h5 class="fw-bold">{{ institution?.name }}</h5>
                            <h5 class="fw-bold">{{ institution?.motto }}</h5>
                            <p class="mb-0 text-xs">{{ institution?.postal }}</p>
                            <p class="mb-0 text-xs">TEL: {{ institution?.telephone }}/ MOB:{{ institution?.mobile }}</p>
                            <p class="mb-0 text-xs">EMAIL: {{ institution?.email }}</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <div class="row mb-2">
                                <div class="col-md-2">
                                    <p class="mb-0">Investment No:</p>
                                </div>
                                <div class="col-md-10">
                                    <p class="mb-0 fw-bold">{{ data.code }}</p>
                                </div>
                                <div class="col-md-2">
                                    <p class="mb-0">Full Name:</p>
                                </div>
                                <div class="col-md-10">
                                    <p class="mb-0 fw-bold">{{ getFullName }}</p>
                                </div>
                                <div class="col-md-2">
                                    <p class="mb-0">Amount:</p>
                                </div>
                                <div class="col-md-10">
                                    <p class="mb-0 fw-bold">{{ institution?.currency_code }} {{ displayNumber(data.principal_amount) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <p class="mb-2"> The certificate is to certify that</p>
                            <p class="mb-2 line-1"><span class="fw-bold">{{ getFullName }}</span></p>
                            <div class="mb-2"><span class="">has an investment in </span><span class="line-2"><span
                                        class="fw-bold">{{ institution?.name }}</span></span></div>
                            <p class="mb-4">Subject to the Terms and Condition of Investing</p>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="mb-0">Investment Date: </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0 fw-bold">{{ data.expected_start_date }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0">Maturity Date: </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0 fw-bold">{{ data.maturity_date }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0">Principal Amount: </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0 fw-bold">{{ displayNumber(data.principal_amount) }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0">Expected Interest: </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0 fw-bold">{{ displayNumber(data.interest_expected) }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-auto">
                            <div class="row">
                                <div class="col-md-6">
                                    <p class="mb-0">Rate: </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0 fw-bold">{{ data.interest_rate }}</p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0">Maturity Value: </p>
                                </div>
                                <div class="col-md-6">
                                    <p class="mb-0 fw-bold">{{ displayNumber(data?.maturity_value) }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-12 text-center text-sm">
                            <p class="cert-note">Please Note: Investment which is redeemed before the maturity date shall attract a
                                penal interest of 2% of the accrued interest</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <p>Date: <span class="line-3"></span></p>
                            <span class="me-3">Customer Sign: <span class="line-3"></span></span>
                            <span>Manager Sign: <span class="line-3"></span></span>
                        </div>
                    </div>
                </div>
            </div>

            <template #modal-footer="{ ok }">
                <div class="w-100">
                <!-- begin loader -->
                <!-- <beat-loader-component 
                class="float-left"
                v-if="isLoading"
                :isLoading="isLoading"
                ></beat-loader-component> -->
                <!-- end loader -->
                <!-- Emulate built in modal footer ok and cancel button actions -->
                 <b-button @click="printPDF" type="button" class="akkurate-green-btn download-btn ms-2 float-right"><span class="small"><font-awesome-icon :icon="['fas', 'book']" /> Print</span></b-button>
                 <b-button @click="downloadPDF" class="akkurate-green-btn download-btn float-right"><span class="small"><font-awesome-icon :icon="['fas', 'save']" /> Download</span></b-button>     
               </div>
            </template>

        </b-modal>
    </div>
</template>


<script>
import Logo from '@/views/main/components/Logo.vue'
import html2pdf from "html2pdf.js";
export default {
    props: ['data', 'institution'],
    components:{
        Logo
    },
    data() {
        return {
            base64Image: '' // Initialize base64Image to an empty string
        }
    },
    computed: {
        getFullName() {
            var first_name = this.data?.account?.customer?.first_name != null ? this.data?.account?.customer?.first_name : "";
            var middlename = this.data?.account?.customer?.middlename != null ? this.data?.account?.customer?.middlename : "";
            var last_name = this.data?.account?.customer?.last_name != null ? this.data?.account?.customer?.last_name : "";

            return first_name + ' ' + middlename + ' ' + last_name;
        },
    },

    methods: {
        printPDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector
            // console.log(elementToConvert);

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 0,
                filename: 'certificate.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                // console.log(pdf);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },
        downloadPDF() {
            // console.log(this.report_data);
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: 0,
                filename: 'certificate.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape' },
            };
            // html2pdf().from(content).set(pdfOptions).outputPdf((pdf) => {

            // });
            html2pdf().from(content).set(pdfOptions).save();
        },
        modalShown(){
            
        }
    }
}
</script>

<style>
.institution-size {
    width: 130px;
    height: 130px;
}

.cert-padding {
    height: 100%;
    padding: 8%
}

.line-1 {
    border-bottom: 1px solid #ccc;
    /* width: 100%; */
    margin-left: 100px;
    margin-right: 100px;
}

.line-2 {
    border-bottom: 1px solid #ccc;
    display: inline-block;
    width: 50%;
}

.line-3 {
    border-bottom: 1px solid #ccc;
    display: inline-block;
    width: 20%;
}

.cert-note {
    font-size: 14px;
}
</style>
