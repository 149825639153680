<template>
  <div id="wrapper">
    <SidebarView :operation="true" :fd-scheme="true" :operations-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <b-container>
          <b-row class="m-0">
            <b-breadcrumb class="text-decoration-none" :items="items"></b-breadcrumb>
          </b-row>
          <div class="card card-shape home-box">
            <div class="card-header py-3 d-flex flex-row align-items-center">
              <h6 class="m-0 fw-bold text-green">Edit Loan Scheme</h6>
            </div>
            <div class="card-body">
              <form class="form" method="POST" @submit.prevent="editData">
                <scheme-form :form="form" :add_or_edit="add_or_edit" :approval_options="approval_options"
                  :branch_options="branch_options"></scheme-form>
                <hr />
                <div class="text-center py-3">
                  <button type="submit" :disabled="isSaving"
                    class="btn akkurate-green-btn text-white btn-size my-2 me-2"><font-awesome-icon class="me-2"
                      :icon="['fas', 'save']" />Update</button>
                  <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 me-2"><font-awesome-icon
                      class="me-2" :icon="['fas', 'rotate-right']" />Reset</button>
                </div>
              </form>
            </div>
          </div>
        </b-container>

      </div>
      <Footer></Footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>

</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
// import UsersForm from "./../forms/UsersForm.vue";
import SchemeForm from "../form/SchemeForm.vue";
import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'


export default {
  components: {
    // BeatLoaderComponent,
    // UsersForm,
    SidebarView,
    TopbarView,
    Footer,
    SchemeForm
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
  },
  created() {
    // this.getSchemeDropdown();
    this.getSchemeOptions();
  },
  data() {
    return {
      ...this.initialState(),
      approval_options: [],
      branch_options: [],
      items: [
        {
          text: 'Fixed Deposit Scheme',
          href: '/investment/fixed-deposit-scheme'
        },
        {
          text: 'Edit Fixed Deposit Scheme',
          active: true
        }
      ]
    };
  },
  methods: {
    // async getSchemeDropdown() {
    //   await ApiService.get('/loans/loanProductSchemes/dropdown')
    //     .then((response) => {
    //       this.approval_options = response.data?.loan_approval_levels
    //     }).catch((error) => {
    //       console.log(error);
    //     })
    // },
    getSchemeOptions() {
      this.$Progress.start();
      this.isLoading = true;
      // console.log(this.$route.params.id)
      // axios
      //   .get("roles/dropdown")
      ApiService.get(`/investments/fixed-term-deposit-schemes/${this.$route.params.id}`)
        .then((response) => {
          this.approval_options = response.data?.approval_options
          this.branch_options = response.data?.branches;
          this.form.name = response.data?.data?.name;
          this.form.interest_rate = response.data?.data?.interest_rate;
          this.form.tenor = response.data?.data?.tenor;
          this.form.branch = response.data?.data?.branch;
          this.form.fixed_deposit_approval_levels = response.data?.data?.approvals.map(index => {
            return {
              id: index.fixed_deposit_approval_level.id,
              name: index.fixed_deposit_approval_level.name,
            }
          });
          this.isLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    editData() {
      this.isSaving = true;
      this.$Progress.start();
      if(this.form.fixed_deposit_approval_levels)
      {
        this.form.fixed_deposit_approval_level = [];
        this.form.fixed_deposit_approval_levels.forEach(element => {
          // console.log(element)
          this.form.fixed_deposit_approval_level.push(element.id)
        });
      }
      if(this.form.branch){
        this.form.branch_id = this.form.branch.id
      }
      

      ApiService.put(`/investments/fixed-term-deposit-schemes/${this.$route.params.id}`, this.form)
        .then((response) => {
          this.isLoadingSaving = false;
          this.$Progress.finish();

          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          // this.form.reset();

          // this.form = this.initialState().form;
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });
    },
    initialState() {
      return {
        form: {
          name: "",
          interest_rate: "",
          tenor: "",
          fixed_deposit_approval_levels: [],
          branch_id: "",
          branch:null,
          errors: []
        },
        isLoading: true,
        isSaving: false,
        add_or_edit: 'edit',
      };
    },
  },
};
</script>

<!-- New step!
         Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
