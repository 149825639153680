<template>
    <div id="wrapper">
        <SidebarView :all-loans="true" :loanVisible="true" :loan="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid">
                    <div class="card card-shape home-box">
                        <div class="card-header d-flex flex-row align-items-center">
                            <h6 class="m-0 fw-bold text-green">All Loans</h6>
                        </div>
                        <div class="card-body table-responsive">
                            <div class="card card-shape home-box mb-3 border border-success">
                                <div class="card-body py-2">
                                    <form @submit.prevent="filterTable">
                                        <loan-filter :form='form' :branch_option="branch_option"
                                            :schemes_option="schemes_option" @branch_arr="branch_array"
                                            @schemes_arr="schemes_array"></loan-filter>
                                    </form>

                                </div>
                            </div>
                            <div>
                                <vue-good-table styleClass="vgt-table bordered table-dropdown striped" mode="remote"
                                    ref="loanTable" :columns="columns" :rows="rows" :isLoading.sync="isLoading"
                                    :totalRows="totalRecords" :sort-options="{
                                        enabled: false
                                    }" :pagination-options="{
                                        enabled: true,
                                        perPage: serverParams.perPage,
                                    }" @on-search="onSearch" @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange">
                                    <template slot="table-row" slot-scope="props">
                                        <span class="d-flex" v-if="props.column?.field == 'action'">
                                            <!-- View Action -->
                                            <a title="View" class="btn btn-sm akkurate-warning text-white border-0"
                                                @click="onViewLoan(props.row, 'view')">
                                                <span class="akkurate-small">
                                                    <font-awesome-icon :icon="['fas', 'eye']" />
                                                </span>
                                            </a>

                                            <!-- <loan-view :data="props.row" :user="user" :institution="institution"
                                                :coa_subheads="coa_subheads"></loan-view> -->
                                            <div class="dropdown no-arrow d-inline">
                                                <a title="Loan Entries/Statement"
                                                    v-if="props.row.loan?.status.toUpperCase() !== 'UNDER_REVIEW' && props.row.loan?.status.toUpperCase() === 'RUNNING'"
                                                    class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"> <font-awesome-icon
                                                            :icon="['fas', 'book']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                     v-if="props.row.loan?.status.toUpperCase() !== 'UNDER_REVIEW' && props.row.loan?.status.toUpperCase() === 'RUNNING'"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Statements/Entries</div>
                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(props.row, 'statement')">Loan
                                                        Statement</button>
                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(props.row, 'entries')">Loan Entries</button>

                                                    <!-- <loan-entries :data="props.row" :entries="'entries'"
                                                        :institution="institution" :branch="props.row?.branch"
                                                        :user="user"></loan-entries> -->

                                                    <!-- <loan-statement :data="props.row" :statement="'statement'"
                                                        :institution="institution"
                                                        :branch="props.row?.branch"></loan-statement> -->
                                                </div>
                                            </div>
                                            <div class="dropdown no-arrow d-inline">
                                                <button title="More Action"
                                                    class="btn btn-sm akkurate-teal text-white dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"> <font-awesome-icon
                                                            :icon="['fas', 'money-bill-transfer']" /></span>
                                                </button>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">More Actions</div>
                                                    <button class="dropdown-item"
                                                        v-if="props.row.loan?.status.toUpperCase() !== 'UNDER_REVIEW' && props.row.loan?.status.toUpperCase() !== 'PENDING'"
                                                        @click="onViewLoan(props.row, 'repay')">Loan Repayment</button>
                                                    <button class="dropdown-item"
                                                        v-if="props.row.loan?.status.toUpperCase() !== 'UNDER_REVIEW' && props.row.loan?.status.toUpperCase() !== 'PENDING'"
                                                        @click="onViewLoan(props.row, 'topup')">Loan Topup</button>
                                                    <!-- <loan-topup :data="props.row" :institution="institution"
                                                        :topup="'topup'"
                                                        :key="props.row.id + 'LoanTOPUPS'"></loan-topup> -->

                                                    <button class="dropdown-item"
                                                        @click="onViewLoan(props.row, 'adjustment')">Loan
                                                        Adjustment</button>
                                                    <!-- <loan-adjustment :key="props.row.id + 'LoanADJUSTMT'"
                                                        :data="props.row" :institution="institution"
                                                        :adjustment="'adjustment'"></loan-adjustment> -->

                                                    <button class="dropdown-item"
                                                        v-if="props.row.loan?.status.toUpperCase() !== 'PENDING' && props.row.loan?.status.toUpperCase() !== 'UNDER_REVIEW'"
                                                        @click="onViewLoan(props.row, 'status')">Change Status</button>


                                                    <button class="dropdown-item"
                                                        v-if="props.row.loan?.status.toUpperCase() !== 'PENDING' && props.row.loan?.status.toUpperCase() !== 'UNDER_REVIEW'"
                                                        @click="onViewLoan(props.row, 'refinance')">Refinance</button>
                                                   
                                                    <button class="dropdown-item"
                                                        v-if="props.row.loan?.status.toUpperCase() !== 'PENDING' && props.row.loan?.status.toUpperCase() !== 'UNDER_REVIEW'"
                                                        @click="onViewLoan(props.row, 'write_off')">Write off</button>
                                                   
                                                </div>
                                            </div>
                                            <!-- delete -->
                                            <button title="Delete" class="btn btn-sm akkurate-red-btn text-end text-white"
                                                v-if="(props.row?.loan?.status?.toUpperCase() == 'PENDING' || props.row?.loan?.status?.toUpperCase() == 'UNDER_REVIEW' || props.row?.loan?.status?.toUpperCase() == 'REJECTED')"
                                                @click="deleteLoan(props.row)" variant="primary">
                                                <span class="akkurate-small"> <i class="fas fa-trash"></i></span>
                                            </button>
                                        </span>
                                        <span v-else-if="props.column?.field == 'customer_full_name'">
                                            <span>{{ props.row?.customer?.first_name }} {{
                                                props.row?.customer?.last_name }}</span>

                                        </span>
                                        <!-- <span v-else-if="props.column?.field == 'total'">
                                                    <span>{{ props.row?.loan?.principal_balance + props.row?.loan?.interest_balance }}</span>
                                                </span> -->
                                        <span v-else-if="props.column?.field == 'loan.loan_balance'">
                                            <span>{{ displayNumber(props.row?.loan?.loan_balance) }}</span>
                                        </span>

                                    </template>

                                    <!-- <template slot="table-row" slot-scope="props">
                                            <span v-if="props.column.field == 'member_fullname'">
                                              <span>{{ props.row?.customer?.first_name }} {{ props.row?.customer?.last_name }}</span> 
                                            </span>
                                            <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                            </span>
                                        </template> -->
                                </vue-good-table>
                                <!-- Modal: Loan View (Unique for Each Row) -->
                                <b-modal :id="'loan-modal-' + currentLoanId" size="xxl" @shown="showView">
                                    <template #modal-title>
                                        <h6 class="m-0 fw-bold text-green">Loan View</h6>
                                    </template>

                                    <!-- Loan View Component -->
                                    <loan-view :data="currentLoanData" :user="user" :institution="institution"
                                        :coa_subheads="coa_subheads" 
                                        :term_active_menu="term_active_menu"
                                        :repayment_active_menu="repayment_active_menu"
                                        :statement_active_menu="statement_active_menu"
                                        :entries_active_menu="entries_active_menu"
                                        :adjustment_active_menu="adjustment_active_menu"
                                        :topup_active_menu="topup_active_menu"
                                        :refinance_active_menu="refinance_active_menu"
                                        :writeoff_active_menu="writeoff_active_menu" 
                                        :user_role="user_role"
                                        :status_active_menu="status_active_menu"
                                        :current_user_id="current_user_id"></loan-view>

                                    <template #modal-footer>
                                        <div class="w-100">
                                            <b-button @click="$bvModal.hide('loan-modal-' + currentLoanId)"
                                                type="submit" class="akkurate-gray-btn btn-size float-right">
                                                <font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close
                                            </b-button>
                                        </div>
                                    </template>
                                </b-modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import LoanFilter from '../forms/LoanFilter.vue';
// import LoanEntries from '../entries/LoanEntries.vue'
// import LoanAdjustment from '../adjustment/LoanAdjustment.vue'
// import LoanRepayment from '../repayment/LoanRepayment.vue'
// import LoanDisbursement from '../disbursement/LoanDisbursement.vue'
import LoanView from '../view/LoanView.vue'
import ApiService from "@/core/services/api.service";
// import LoanStatement from '../statements/LoanStatement.vue'
// import LoanAuthorization from '../authorization/LoanAuthorization'
// import LoanStatus from '../status/LoanStatus.vue'
// import LoanTopup from '../topup/LoanTopup.vue'
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        LoanFilter,
        // LoanEntries,
        // LoanAdjustment,
        // LoanRepayment,
        LoanView,
        // LoanStatement,
        // LoanDisbursement,
        // LoanAuthorization,
        // LoanStatus,
        // LoanTopup,
    },
    data() {
        return {
            totalRecords: 0,
            searchFilter: "",
            isLoading: true,
            isSaving: false,
            institution: {},
            statement: "statement",
            entries: "entries",
            currentLoanId: null, // Track the currently viewed loan ID
            currentLoanData: null, // Track the current loan's data to display in the modal
            term_active_menu: true, // Default active tab is "Loan Term"
            repayment_active_menu: false, // Default inactive tab is "Repayments"
            statement_active_menu: false,
            entries_active_menu: false,
            adjustment_active_menu: false,
            topup_active_menu: false,
            refinance_active_menu: false,
            writeoff_active_menu: false,
            status_active_menu: false,
            user_role: "",
            current_user_id:"",

            form: {
                branch: "",
                filter: "",
                schemes: "",
                search: "",
                loan_number: "",
                branch_ids: "",
                schemes_ids: "",
                status_option: ['PENDING', 'UNDER_REVIEW', 'RUNNING', 'DISBURSED', 'CLOSED', 'REJECTED', 'WRITTEN_OFF'],
                status: ""
            },
            user: [],
            branch_option: [],
            schemes_option: [],
            user_branch: [],
            coa_subheads: {},
            columns: [

                {
                    label: 'Loan #',
                    field: 'loan.loan_number',
                },
                {
                    label: 'Loan Name',
                    field: 'loan.loan_product.name',
                },
                {
                    label: 'Borrower Name',
                    field: 'customer_full_name',
                },
                {
                    label: 'Principal',
                    field: 'loan.principal_amount',
                },
                {
                    label: 'Principal.Bal',
                    field: 'loan.principal_balance',
                },
                {
                    label: 'Expected Interest',
                    field: 'loan.expected_interest',
                },
                {
                    label: 'Interest Bal.',
                    field: 'loan.interest_balance',
                },
                {
                    label: 'Total Bal.',
                    field: 'loan.loan_balance',
                },
                {
                    label: 'Start Date',
                    field: 'loan.interest_start_date',
                },
                {
                    label: 'Status',
                    field: 'loan.status',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            serverParams: {
                // a map of column filters example: {name: 'john', age: '20'}
                columnFilters: {
                },
                sort: [
                    {
                        field: '', // example: 'name'
                        type: '' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 20 // how many items I'm showing per page
            },
        }
    },
    mounted() {
        this.getLoanDropdown();
        this.getInsistution();
    },
    methods: {
        async getLoanDropdown() {
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.get("/loans/paginate/dropdown")
                .then((response) => {
                    // console.log(response);
                    this.branch_option = response?.data.branches
                    this.schemes_option = response?.data?.schemes
                    this.user = response?.data?.user
                    this.coa_subheads = response?.data?.coa_subheads
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                    // console.log(error);
                })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params.sortType,
                    field: this.columns[params.columnIndex].field,
                }],
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        onSearch(event) {
            this.loadItems(event.searchTerm)
        },
        filterTable() {
            this.serverParams.page = 1
            this.loadItems();
        },

        // load items is what brings back the rows from server
        async loadItems(searchFilter = null) {
            //   getFromServer(this.serverParams).then(response => {
            //      this.totalRecords = response.totalRecords;
            //      this.rows = response.rows;
            //   });
            this.$Progress.start();
            this.isLoading = true;
            // console.log(this.form.schemes_ids);
            await ApiService.post("/loans/paginate",
                {
                    "page": this.serverParams.page,
                    "per_page": this.serverParams.perPage,
                    "scheme_ids": this.form.schemes_ids,
                    "branch_ids": this.form.branch_ids,
                    // "filter": searchFilter,
                    "filter": this.form.filter,
                    "loan_number": this.form?.loan_number,
                    "status": this.form.status
                })
                .then((response) => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.meta.total;
                    this.isLoading = false;
                    this.$Progress.finish();

                }).catch((error) => {
                    // console.log(error);
                })
                .finally((error) => {
                    // console.log(error);
                });
        },
        branch_array(event) {
            // console.log(event);
            this.form.branch_ids = [];
            this.form.branch_ids = event;
            //    console.log(this.form.branch_ids);
        },
        schemes_array(event) {
            this.form.schemes_ids = [];
            this.form.schemes_ids = event;
            // console.log(this.form.schemes_ids);
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        approval() {
            this.loadItems();
        },
        // Triggered when "View" button is clicked on a row
        onViewLoan(row, type) {
            this.currentLoanId = row.id; // Set the current loan ID
            this.currentLoanData = row; // Set the data of the selected loan to be displayed in the modal
            // Ensure this is done immediately
            // Set the active tab based on the 'type' parameter
            if (type === 'view') {
                this.term_active_menu = true;
                this.repayment_active_menu = false;
                this.statement_active_menu = false;
                this.entries_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'repay') {
                this.term_active_menu = false;
                this.repayment_active_menu = true;
                this.statement_active_menu = false;
                this.entries_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'entries') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = true;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'statement') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = true;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            }else if (type === 'adjustment') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = true;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            }else if (type === 'topup') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = true;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if (type === 'status') {
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = false;
                this.status_active_menu = true;
            } else if(type ===  'refinance'){
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = true;
                this.writeoff_active_menu = false;
                this.status_active_menu = false;
            } else if(type ===  'write_off'){
                this.term_active_menu = false;
                this.repayment_active_menu = false;
                this.entries_active_menu = false;
                this.statement_active_menu = false;
                this.adjustment_active_menu = false;
                this.topup_active_menu = false;
                this.refinance_active_menu = false;
                this.writeoff_active_menu = true;
                this.status_active_menu = false;
            }
            this.$nextTick(() => {
                this.$bvModal.show('loan-modal-' + this.currentLoanId);
            });
        },
        showView() {
            // Optional: Additional logic when the modal is shown
            this.user_role = localStorage.getItem("user_role")
            this.current_user_id = localStorage.getItem("userId")
        },
        deleteLoan(data) {
            swal.fire({
                title: "Delete Loan",
                text: 'Are you sure you want to delete loan number ' + data.loan.loan_number + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                cancelButtonText: "NO",
                allowOutsideClick: true,
                customClass: {
                    cancelButton: 'btn akkurate-green-gray modal-btn-width text-white me-2', // Custom class for the "NO" button
                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "YES" button
                }
            }).then(result => {
                if (result.value) {

                    this.$Progress.start();
                    ApiService.delete('/loans/' + data.loan.id)
                        .then((response) => {
                            this.$Progress.finish();
                            this.loadItems();
                            // this.$bvToast.toast('Loan Deleted Successfully', {
                            //     title: 'Success',
                            //     variant: 'success',
                            //     solid: true
                            // })
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.loadItems();
                        }).catch((error) => {
                            this.$Progress.fail();

                            swal.fire({
                                // title: response.statusText,
                                text: error.response.data.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });

        }
    }
}

</script>