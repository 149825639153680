<template>
  <div id="wrapper">
    <SidebarView :accType="true" :accTypeVisible="true" :allMemType="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-9">
              <label class="akkurate-dark" for="name">Search Term <star></star></label>
              <b-form-group id="name" label-for="name" class="akkurate-dark">
                <b-form-input id="name" class="mt-2 field-container fields" type="text" placeholder="Enter Name"
                  required>
                </b-form-input>
              </b-form-group>
            </div>
            <div class="mt-auto col-12 col-md-3">
              <b-button class="akkurate-green-btn akkurate-auth-size w-100"><span class="small"><font-awesome-icon
                    :icon="['fas', 'search']" /> Search</span></b-button>
            </div>
            <div class="my-3">

            </div>
          </div>
          <div class="card card-shape home-box">
            <div class="card-header d-flex flex-row align-items-center justify-content-between">
              <h6 class="m-0 fw-bold text-green">All Accounts</h6>
              <b-button class="btn btn-sm akkurate-green-btn" href="/members/account-type/create" variant="primary">
                <span class="akkurate-small">
                  <i class="fas fa-plus"></i> Add Account Type</span>
              </b-button>
            </div>
            <div class="card-body">
              <div>
                <vue-good-table styleClass="vgt-table bordered striped" mode="remote" @on-page-change="onPageChange"
                  @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :isLoading.sync="isLoading"
                  :rows="rows" :columns="columns" :pagination-options="{
                    enabled: true,
                    perPage: serverParams.perPage,
                  }">
                  <!-- Add your custom delete button column -->
                  <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'd_allowed'">
                      <span v-if="props.row.deposit_allowed == 1">
                        <span>Yes</span>
                      </span>
                      <span v-else>
                        <span>No</span>
                      </span>
                    </span>
                    <span v-else-if="props.column.field == 'w_allowed'">
                      <span v-if="props.row.withdrawal_allowed == 1">
                        <span>Yes</span>
                      </span>
                      <span v-else>
                        <span>No</span>
                      </span>
                    </span>
                    <span v-else-if="props.column.field == 'send_sms'">
                      <span v-if="props.row.send_sms == 1">
                        <span>Yes</span>
                      </span>
                      <span v-else>
                        <span>No</span>
                      </span>
                    </span>
                    <span class="d-flex" v-else-if="props.column.field == 'action'">
                      <account-type-view :key="props.row.id" :data="props.row" :view="'view'"></account-type-view>
                      <a title="Edit" class="btn btn-sm akkurate-primary text-white"
                        :href="'/members/account-type/' + props.row.id + '/edit'">
                        <span class="akkurate-small">
                          <font-awesome-icon :icon="['fas', 'edit']" />
                        </span>
                      </a>
                      <button title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                        @click="confirmDelete(props.row)">
                        <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'trash']" /></span>
                      </button>
                    </span>
                  </template>
                </vue-good-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import AccountTypeView from "../view/AccountTypeView.vue";
import ApiService from "@/core/services/api.service";
export default {
  components: {
    SidebarView,
    TopbarView,
    Footer,
    AccountTypeView,
  },
  data() {
    return {
      totalRecords: 0,
      isLoading: false,
      columns: [
        // {
        //   label: "ID",
        //   field: "id",
        //   type: "text",
        // },
        {
          label: "Name",
          field: "name",
          type: "text",
        },
        {
          label: "Base Type",
          field: "base_type",
          type: "text",
        },
        {
          label: "Code",
          field: "code",
          type: "text",
        },
        {
          label: "Deposit Allowed",
          field: "d_allowed",
        },
        {
          label: "Withdrawal Allowed",
          field: "w_allowed",
        },
        {
          label: "Minimum Bal. (GHS)",
          field: "minimum_balance",
        },
        {
          label: "Created At",
          field: "created_at",
          type: "text",
        },
        {
          label: "Send Sms",
          field: "send_sms",
          type: "text",
        },
        {
          label: "Action",
          field: "action",
          //   formatFn: this.renderActions,
        },
      ],
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {},
        sort: [
          {
            field: "", // example: 'name'
            type: "", // 'asc' or 'desc'
          },
        ],
        page: 1, // what page I want to show
        perPage: 20, // how many items I'm showing per page
      },
    };
  },
  mounted() {
    // this.getAccountDropdown();
    this.loadItems();
  },
  methods: {
    // confirmDeleteUser(user) {
    //   swal
    //     .fire({
    //       title: "Disable User",
    //       text: `Are you sure you want to disable ${user.username}?`,
    //       icon: "warning",
    //       showCancelButton: false,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "Ok",
    //       allowOutsideClick: false,
    //     })
    //     .then((result) => {
    //       if (result.value) {
    //         //   window.location.reload();
    //       }
    //     });
    // },
    // load items is what brings back the rows from server
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async loadItems() {
      this.isLoading = true;
      await ApiService.post("/accounts/account-types/paginate", this.serverParams)
        .then((response) => {
          console.log(response.data.data);
          this.rows = response.data.data;
          this.totalRecords = response.data.meta.total;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          // console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          // this.isLoading = false
        });
    },
    confirmDelete(data) {
      swal.fire({
        title: "Delete Fixed deposit",
        text: 'Are you sure you want to delete fixed deposit ?',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "YES",
        cancelButtonText: "NO",
        allowOutsideClick: true,
        customClass: {
          confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
          cancelButton: 'btn akkurate-danger-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
        }
      }).then(result => {
        if (result.value) {
          this.$Progress.start();
          ApiService.delete('/investments/fixed-term-deposit-schemes/' + data.id)
            .then((response) => {
              this.$Progress.finish();
              this.loadItems();
              swal.fire({
                // title: response.statusText,
                text: "Fixed deposit Successfully Deleted",
                icon: "success",
                allowOutsideClick: true,
                allowEscapeKey: true,
                showCancelButton: false, // Show the "Cancel" button
                confirmButtonText: 'okay', // Customize the text for the "OK" button
                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                customClass: {
                  confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                }
              });
              this.loadItems();
            }).catch((error) => {
              this.$Progress.fail();

              swal.fire({
                // title: response.statusText,
                text: error.response.data.message,
                icon: "error",
                allowOutsideClick: true,
                allowEscapeKey: true,
                showCancelButton: false, // Show the "Cancel" button
                confirmButtonText: 'okay', // Customize the text for the "OK" button
                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                customClass: {
                  confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                }
              });
            })
        }
      });

    },
    initialState() {
      return {
        totalRecords: 0,
        searchFilter: "",
        form: {
          branch: null,
          branch_option: [],
          acc_types: "",
          acc_types_option: [],
          phone: "",
          start_date: "",
          end_date: "",
          status: "",
          status_option: ["Active", "Inactive", "Dormant", "Closed"],
        },
        isLoading: true,
        isSaving: false,
        add_or_edit: "add",
      };
    },
  },
};
</script>
