<template>
  <div id="wrapper">
    <Sidebar :mobile="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <Topbar />
        <MobileMenu />
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="d-flex justify-content-between">
                <h4 class="akkurate-dark">Transactions History</h4>
                <p class="akkurate-gray akkurate-small"> GHC {{ displayNumber(getTotalAmount) }} | {{ getTotalCount }}</p>
              </div>

            </div>
            <hr />
            <form @submit.prevent="searchMember">
              <div class="row">
                <div class="col-6 mb-4">
                  <b-form-group id="start_date" label-for="start_date">
                    <b-form-input class="field-container fields" type="date" v-model="start_date"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6 mb-4">
                  <b-form-group id="end_date" label-for="end_date">
                    <b-form-input class="field-container fields" type="date" v-model="end_date"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-4 mb-4">
                  <div class="input-group">
                    <span class="input-group-text py-0 px-2 bg-white" id="basic-addon1"><font-awesome-icon
                        class="icon_color" :icon="['fas', 'filter']" /></span>
                    <multiselect class="field-container custom-group custom-border" v-model="code"
                      :options="code_option" label="name" selectLabel="" deselectLabel="">
                    </multiselect>
                  </div>
                </div>
                <div class="col-5 mb-4">
                  <b-form-group id="search" prepend="$" label-for="search">
                    <b-input-group class="">
                      <template #prepend>
                        <b-input-group-text class="py-0 px-2 h-100 bg-white">
                          <font-awesome-icon class="icon_color" :icon="['fas', 'search']" /></b-input-group-text>
                      </template>
                      <b-form-input class="field-container fields border-start-0" type="text" v-model="search"
                        placeholder="Search by old code, customer code ..."></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-3 mb-4">
                  <div class="d-grid gap-2">
                    <b-button type="submit" :disabled="is_searching"
                      class="fw-semibold akkurate-auth-size akkurate-green-btn">
                      <font-awesome-icon class="me-2" :icon="['fas', 'search']" /> {{ search_btn }}
                    </b-button>
                  </div>
                </div>
              </div>
            </form>

            <vue-good-table styleClass="vgt-table bordered striped" mode="remote" ref="allAccountTable"
              :columns="columns" :rows="rows" :isLoading.sync="isLoading" :totalRows="totalRecords" :pagination-options="{
                enabled: true,
                perPage: serverParams.perPage,
                setCurrentPage: serverParams.page,
              }" @on-page-change="onPageChange" @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange">
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'full_name'">
                  <span>{{ props.row.customer.first_name + ' ' + props.row.customer.last_name }}</span>
                </span>
                <span v-if="props.column.field == 'prod_type'">
                  <span>{{ props.row.account.account_type.name }}</span>
                </span>
                <span v-if="props.column.field == 'account_number'">
                  <span>{{ props.row.account.account_number }}</span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>
<script>
import Topbar from "@/views/main/components/Topbar.vue";
import Sidebar from "@/views/main/components/Sidebar.vue";
import MobileMenu from "../../../components/MobileMenu.vue";
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
export default {
  components: {
    MobileMenu,
    Topbar,
    Sidebar,
    Multiselect,
  },
  data() {
    return {
      form: {
        account_no: "",
        member_name: "",
        telephone: "",
        product: "",
        amount: "",
        errors: {},
      },
      isLoading: false,
      totalRecords: 0,
      code: { name: "AC", value: "account_number" },
      code_option: [
        { name: "AC", value: "account_number" },
        { name: "Filter", value: "filter" },
        { name: "PH", value: "phone_number" },
      ],
      start_date: "",
      end_date: "",
      search: "",
      search_btn: "Search",
      mobile_menu: false,
      is_searching: false,
      columns: [
        {
          label: "Date",
          field: "trans_date",
          type: "text",
        },
        {
          label: "Cust.Name",
          field: "full_name",
          type: "text",
        },
        {
          label: "Trans.Type",
          field: "transaction_type",
          type: "text",
        },
        {
          label: "Prod.Type",
          field: "prod_type",
          type: "text",
        },
        {
          label: "Acc.No",
          field: "account_number",
          type: "text",
        },
        {
          label: "Amount",
          field: "trans_amount",
          type: "text",
        },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "", // example: 'name'
            type: "", // 'asc' or 'desc'
          },
        ],
        page: 1, // what page I want to show
        perPage: 5, // how many items I'm showing per page
      },
    };
  },
  computed: {
    getFullName() {

    },
    getTotalAmount() {
      return this.rows.reduce((sum, row) => {
        return sum + (row.trans_amount || 0); // Ensure that undefined or null trans_amounts are treated as 0
      }, 0);
    },
    getTotalCount() {
      return this.rows.length; // Returns the count of items in the 'rows' array
    }
  },
  mounted() {
    this.loadTransactions();
  },
  methods: {
    submitFormFilters() {
      //this.serverParams.page = 1;
      //currentPage
      this.updateParams({ page: 1 });
      this.loadTransactions();
    },
    updateParams(newProps) {
      //console.log("new parms", newProps)
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadTransactions();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadTransactions();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.loadTransactions();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadTransactions();
    },
    async loadTransactions(searchFilter = null) {
      this.isLoading = true;
      this.is_searching = true;
      this.search_btn = "processing...";
      let ac = "";
      let filter = "";
      let phone = "";
      if (this.code?.value == "account_number") {
        ac = this.search.toString();
        this.search.toString();
      } else if (this.code?.value == "filter") {
        filter = this.search.toString();
      } else if (this.code?.value == "phone_number") {
        phone = this.search.toString();
      } else {
        filter = this.search.toString();
      }
      this.$Progress.start();
      const teller_id = localStorage.getItem('userId')
      await ApiService.post("/transactions/transaction_by_teller", {
        page: this.serverParams.page,
        per_page: this.serverParams.perPage,
        filter: filter,
        branch_id: "",
        account_type_id: "",
        account_number: ac,
        mobile: phone,
        start_date: this.start_date,
        end_date: this.end_date,
        status: "", //Inactive,	Active, Dormant, Closed
        code: "",
        transaction_type: "",
        external_reference: "",
        teller_id: teller_id
      })
        .then((response) => {
          console.log(response.data.data);
          this.rows = response.data?.data;
          this.totalRecords = response.data.meta.total;
          this.all_data = response.data.data;
          this.$Progress.finish();
          this.isLoading = false;
          this.is_searching = false;
          this.search_btn = "Search";
        })
        .catch((error) => {
          // console.log(error);
          this.isLoading = false;
          this.$Progress.fail();
          this.is_searching = false;
          this.search_btn = "Search";
        })
        .finally(() => {
          this.isLoading = false;
          this.$Progress.finish();
          this.is_searching = false;
          this.search_btn = "Search";
        });
    },
    searchMember() {
      this.loadTransactions();
    },
    // filterTable() {
    //   this.loadTransactions();
    // },
  },
};
</script>

<style scoped>
.input-group>.custom-group {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.icon_color {
  color: #c1c2c4;
}
</style>