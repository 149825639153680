<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="account_type">Account Type <star></star></label>
          <b-form-group id="account_type" label-for="account_type">
            <multiselect class="mt-2 field-container" v-model="form.account_type" :options="account_options"
              label="name">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="account_balance">Balance <star></star></label>
          <b-form-group id="account_balance" label-for="account_balance" class="akkurate-dark">
            <b-form-input id="account_balance" v-model="form.account_balance" class="mb-3 mt-2 field-container fields"
              type="number" placeholder="Enter Balance" disabled>
            </b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="member">Member <star></star></label>
          <b-form-group id="member" label-for="member">
            <multiselect class="mt-2 field-container" v-model="form.member" :options="member_options"
              :custom-label="fullName" placeholder="Select member by name" label="name" track-by="id"
              @select="onSelect_member(form.member)">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="status">Status <star></star></label>
          <b-form-group id="status" label-for="status">
            <multiselect class="mt-2 field-container" v-model="form.status" :options="form.status_option">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-6 mt-2">
          <label class="akkurate-dark" for="balance">Branch</label>
          <b-form-group label-for="branch" class="akkurate-dark">
            <b-form-input id="branch" class="mb-3 mt-2 field-container fields" type="text" v-model="form.branch.name"
              placeholder="Enter Branch" required disabled>
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6 mt-2">
          <b-form-group label-for="notes" class="mb-2">
            <template #label>
              <label class="akkurate-dark" for="notes">Comment</label>
            </template>
            <b-form-textarea class="mt-2 fields" id="notes" v-model="form.notes"
              placeholder="Enter Notes..."></b-form-textarea>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="withdrawal_limit">Withdrawal Limit </label>
          <b-form-group label-for="withdrawal_limit" class="akkurate-dark">
            <b-form-input id="withdrawal_limit" class="mb-3 mt-2 field-container fields" type="text"
              v-model="form.withdrawal_limit" placeholder="Enter Withdrawal limit">
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-6">
          <label class="akkurate-dark" for="maximum_withdrawal_frequency">Maximum withdrawal frequency
          </label>
          <b-form-group id="maximum_withdrawal_frequency" label-for="maximum_withdrawal_frequency">
            <multiselect class="mt-2 field-container" v-model="form.maximum_withdrawal_frequency"
              :options="frequency_options">
            </multiselect>
          </b-form-group>
        </div>

        <div class="col-12 col-md-2">
          <b-form-group>
            <template #label>
              <label class="akkurate-dark" for="apply_commission">Apply interest</label>
            </template>
            <b-form-radio @change="interestSeleted" v-model="form.apply_interest" name="apply_interest"
              value="1">Yes</b-form-radio>
            <b-form-radio @change="interestSeleted" v-model="form.apply_interest" name="apply_interest"
              value="0">No</b-form-radio>
          </b-form-group>
        </div>

        <div class="col-12 col-md-3" v-show="apply_interest_show">
          <label class="akkurate-dark" for="interest_rate">Interest rate </label>
          <b-form-group label-for="interest_rate" class="akkurate-dark">
            <b-form-input id="interest_rate" class="mb-3 mt-2 field-container fields" type="text"
              v-model="form.interest_rate" placeholder="Enter Interest Rate">
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-4" v-show="apply_interest_show">
          <label class="akkurate-dark" for="interest_frequency">Interest frequency
          </label>
          <b-form-group id="interest_frequency" label-for="interest_frequency">
            <multiselect class="mt-2 field-container" v-model="form.interest_frequency" :options="frequency_options">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3" v-show="apply_interest_show">
          <label class="akkurate-dark small" for="account_interest_start_date">Interest Start Date</label>
          <b-form-group id="account_interest_start_date" label-for="account_interest_start_date">
            <b-form-input class="mt-2 field-container fields" type="date" v-model="form.account_interest_start_date"></b-form-input>
          </b-form-group>
        </div>

      

        <div class="col-12 col-md-2">
          <b-form-group>
            <template #label>
              <label class="akkurate-dark" for="apply_commission">Apply commission</label>
            </template>
            <b-form-radio @change="commissionSeleted" v-model="form.apply_commission" name="apply_commission"
              value="1">Yes</b-form-radio>
            <b-form-radio @change="commissionSeleted" v-model="form.apply_commission" name="apply_commission"
              value="0">No</b-form-radio>
          </b-form-group>
        </div>

        <div class="col-12 col-md-3" v-show="apply_show">
          <label class="akkurate-dark" for="commission">Commission Amount </label>
          <b-form-group label-for="commission" class="akkurate-dark">
            <b-form-input id="commission" class="mb-3 mt-2 field-container fields" type="text" v-model="form.commission"
              placeholder="Enter Commission Amount">
            </b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 col-md-4" v-show="apply_show">
          <label class="akkurate-dark" for="commission_frequency">Commision frequency
          </label>
          <b-form-group id="commission_frequency" label-for="commission_frequency">
            <multiselect class="mt-2 field-container" v-model="form.commission_frequency" :options="frequency_options">
            </multiselect>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3" v-show="apply_show">
          <label class="akkurate-dark small" for="account_commission_start_date">Commission Start Date</label>
          <b-form-group id="account_commission_start_date" label-for="account_commission_start_date">
            <b-form-input class="mt-2 field-container fields" type="date" v-model="form.account_commission_start_date"></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-2">
          <b-form-group>
            <template #label>
              <label class="akkurate-dark" for="auto_transfer">Auto transfer</label>
            </template>
            <b-form-radio v-model="form.auto_transfer" name="auto_transfer" value="1">Yes</b-form-radio>
            <b-form-radio v-model="form.auto_transfer" name="auto_transfer" value="0">No</b-form-radio>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import ApiService from "@/core/services/api.service";

export default {
  name: 'AccountForm',
  components: {
    Multiselect
  },
  data() {
    return {
      frequency_options: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Mid-year', 'Annually'],
    }
  },
  computed: {
    getBranch() {
      if (this.form.branch) {
        return this.form.branch.name ?? ""
      }
    }

  },
  methods: {
    fullName({ first_name, last_name, code }) {
      let name = ""
      if (first_name) {
        name = name + first_name;
      }

      if (last_name) {
        name = name + " " + last_name;
      }

      if (code) {
        name = name + ` (${code})`;
      }

      return `${name}`
    },
    async onSelect_member(member) {
      await ApiService.get("/accounts/create/dropdown")
        .then((response) => {
          // this.form.branch = response.data.members[member.id].branch;
          // console.log(response.data.members[0].branch);
          const branch = response.data.members[[member].indexOf(member)]?.branch ?? ""
          if (branch) {
            this.form.branch = branch
          }
        })
        .catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });

    },


  },
  props: ["form", "add_or_edit", "account_options", "member_options", 'commissionSeleted', 'apply_show','interestSeleted', 'apply_interest_show'],
}
</script>