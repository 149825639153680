<template>
  <div id="wrapper">
    <SidebarView :operation="true" :fd-scheme="true" :operations-visible="true" />
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <b-container>
          <b-row class="m-0">
            <b-breadcrumb :items="items"></b-breadcrumb>
          </b-row>
          <div class="card card-shape home-box">
            <div class="card-header py-3 d-flex flex-row align-items-center">
              <h6 class="m-0 fw-bold text-green">Create Fixed Deposit Scheme</h6>
            </div>
            <div class="card-body">
              <form class="form" method="POST" @submit.prevent="storeData">
                <scheme-form :form="form" :add_or_edit="add_or_edit" 
                :approval_options="approval_options"
                :branch_options="branch_options"></scheme-form>
                <hr />
                <div class="text-center py-3">
                  <SaveButton :isSaving="isSaving" />
                  <button type="reset" class="btn akkurate-gray-btn text-white btn-size my-2 ms-3">
                    <font-awesome-icon class="me-2" :icon="['fas', 'rotate-right']" />
                    Reset</button>
                </div>
              </form>
            </div>
          </div>
        </b-container>

      </div>
      <Footer></Footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
  </div>
</template>

<script>

// import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
// import UsersForm from "./../forms/UsersForm.vue";
import ApiService from "@/core/services/api.service";
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue'
import SaveButton from "@/views/main/components/SaveButton.vue";
import SchemeForm from "../form/SchemeForm.vue";


export default {
  components: {
    name: 'UsersCreate',
    // BeatLoaderComponent,
    // UsersForm,
    SidebarView,
    TopbarView,
    Footer,
    SchemeForm,
    SaveButton
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (!token) {
      this.$router.push({ path: '/login' });
    }
    this.getSchemeDropdown();
    // this.getUserOptions();
  },
  data() {
    return {
      ...this.initialState(),
      items: [
        {
          text: 'Fixed Deposit Scheme',
          href: '/investment/fixed-deposit-scheme'
        },
        {
          text: 'Create Fixed Deposit Scheme',
          active: true
        }
      ]
    };
  },
  methods: {
    storeData() {
      this.isSaving = true;
      this.$Progress.start();
      if(this.form.fixed_deposit_approval_levels)
      {
        this.form.fixed_deposit_approval_level = [];
        this.form.fixed_deposit_approval_levels.forEach(element => {
          // console.log(element)
          this.form.fixed_deposit_approval_level.push(element.id)
        });
      }
      if(this.form.branch){
        this.form.branch_id = this.form.branch.id
      }
      ApiService.post("investments/fixed-term-deposit-schemes", this.form)
        .then((response) => {
          this.isLoadingSaving = false;
          this.$Progress.finish();
          toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.isSaving = false;
          this.form = this.initialState().form;
        }).catch((error) => {
          this.isSaving = false;
          this.$Progress.fail();
          if (error.response.data.errors) {
            this.form.errors = error.response.data.errors;
          }
        });
    },
    initialState() {
      return {
        form: {
          name: "",
          interest_rate: "",
          tenor: "",
          fixed_deposit_approval_levels: [],
          fixed_deposit_approval_level: [],
          branch_id: "",
          branch:null,
          errors: []
        },
        branch_options:[],
        approval_options:[],

        isLoading: true,
        isSaving: false,
        add_or_edit: 'add',
      };
    },
    async getSchemeDropdown() {
      await ApiService.get('/investments/fixed-term-deposits/scheme/dropdown')
        .then((response) => {
          this.approval_options = response.data?.fixed_deposit_approval_levels;
          this.branch_options = response.data?.branches;
        }).catch((error) => {
          console.log(error);
        })
    },
  },
};
</script>

<!-- New step!
       Add Multiselect CSS. Can be added as a static asset or inside a component. -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>