<template>
    <form @submit.prevent="basicSearch">
        <div class="row">
            <div class="col-md-6">
                <b-form-group id="loan_code" label-for="loan_code">
                    <label class="akkurate-dark small" for="search">Search</label>
                    <b-form-input class="mt-2 field-container fields" type="text"
                        placeholder="Search by Member Code, FD Code, Mobile Number ..."
                        v-model="filter.code"></b-form-input>
                </b-form-group>
            </div>
            <div class="col">
                <label class="akkurate-dark small" for="status">Status</label>
                <multiselect class="mt-2 field-container" v-model="filter.status" :options="status_option"></multiselect>
            </div>
            <div class="d-grid gap-2 col-md-2 mt-auto d-flex align-content-center justify-content-center">
                <b-button type="submit" class="akkurate-green-btn akkurate-auth-size w-100"><i
                        class="fas fa-search me-2"></i>Search</b-button>
                <b-button size="sm" @click="show_more" title="show more filters" class="ms-2">
                    <i class="fas fa-bars akkurate-small"></i>
                </b-button>
            </div>
        </div>
        <div class="row mt-2" v-if="more_details">
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="from_date">From Date</label>
                <b-form-group id="from_date" label-for="from_date">
                    <b-form-input class="mt-2 field-container fields" type="date"
                        v-model="filter.from_date"></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="to_date">To Date</label>
                <b-form-group id="to_date" label-for="to_date">
                    <b-form-input class="mt-2 field-container fields" type="date"
                        v-model="filter.to_date"></b-form-input>
                </b-form-group>
            </div>
            <div class="col-12 col-md-3">
                <label class="akkurate-dark small" for="branch">Branch</label>
                <multiselect class="mt-2 field-container" v-model="filter.branch" :options="filter.branch_option"
                    track-by="name" placeholder="Select one" selectLabel="" deselectLabel="" label="name">
                </multiselect>
            </div>
            <div class="col-12 col-md-3" v-show="more_details">
                <label class="akkurate-dark small" for="trans_source">Source</label>
                <multiselect class="field-container mt-2" v-model="filter.trans_source" :options="trans_source_options"
                    placeholder="Transaction Source" label="name" selectLabel="" deselectLabel="">
                </multiselect>
            </div>
        </div>
    </form>
</template>
<script>
import Multiselect from 'vue-multiselect'
export default {
    components: {
        Multiselect
    },
    props: ['filter', 'basicSearch', 'action'],
    data() {
        return {
            search: "Account Number",
            search_options: ["Account Number", "Code", "ID"],
            status_option: ['Pending', 'Running', 'Matured', "Redeemed", "Rejected"],
            more_details: false,
            trans_source_options: [
                { name: 'USSD', value: "ussd" },
                { name: 'WEB', value: "web" },
                { name: 'MOBILE BANKER', value: "mobile_banker" },
                // { name: 'Deposit', value: "deposit" },
                // { name: 'Withdrawal', value: "withdrawal" },
            ],

        }
    },
    methods: {
        show_more() {
            this.more_details = !this.more_details
        },

    }
}
</script>