

<template>
  <div id="wrapper">
    <SidebarView :allCoa="true" :accounts="true" :accounts-visible="true"/>
    <div class="d-flex flex-column" id="content-wrapper">
      <div id="content">
        <TopbarView />
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-10">
                  <h6 class="m-0 fw-bold text-green">Chart Of Accounts</h6>
                </div>
                <div class="col-md-2">
                  <add-coa-head @coa-head-saved="coaHeadSaved" ref="modal">
                  </add-coa-head>
                </div>
              </div>
            </div>
            <div class="card-body">
              <!-- <h5 class="card-title">Special title treatment</h5> -->
              <!-- begin row  -->
              <form id="search-form" @submit.prevent="loadPageOptions" method="GET">
                <div class="row">
                  <div class="col-xl-5 col-md-5 col-sm-6 mt-auto col-5">
                    <p>
                      <label>Select Branches:</label>
                      <multiselect
                        :disabled="add_or_edit == 'edit'"
                        v-model="search_form.selected_branches"
                        :multiple="true"
                        name="selected_branches"
                        placeholder="Search or select"
                        :options="branches"
                        label="name"
                        track-by="id"
                        class="rounded-0"
                      ></multiselect>
                    </p>
                  </div>

                  <div class="col-xl-5 col-md-5 col-sm-6 col-5">
                    <p>
                      Categories
                      <multiselect
                        :disabled="add_or_edit == 'edit'"
                        v-model="search_form.categories"
                        :multiple="true"
                        name="selected_categories"
                        placeholder="Search or select"
                        :options="categories"
                      ></multiselect>
                    </p>
                  </div>
                  
                  <div class="col-xl-3 col-md-3 col-sm-3 col-3 mt-4">
                    <button type="submit" class="btn akkurate-green-btn " style="color: white;"><font-awesome-icon class="me-2" :icon="['fas', 'forward']" />Go</button>
                  </div>
                </div>
              </form>

              <div class="row">
                <div class="col-lg-12">
                  <div class="table-responsive mb-4 mt-4">
                    <!-- <table
                      id="coa_table"
                      class="table table-striped table-bordered"
                      cellspacing="0"
                      width="100%"
                    > -->
                    <div class="custom-table p-2" width="100%">
                      <!-- <thead>
                        <tr>
                          <th class="">Number</th>
                          <th class="">Name</th>
                          <th class="">Type</th>
                          <th class="">Balance</th>
                          <th class="">Branch</th>
                          <th class="">Action</th>
                        </tr>
                      </thead> -->
                      <div class="row header">
                        <div class="cell">Number</div>
                        <div class="cell-2">Name</div>
                        <div class="cell">Type</div>
                        <div class="cell-small">Balance</div>
                        <div class="cell">Branch</div>
                        <div class="cell">Action</div>
                        <!-- <div class="cell">Type</div> -->
                      </div>
                      <div
                        v-for="(row, index) in displayedRows"
                        :key="index"
                        class=""
                      >
                        <div class="row">
                          <div class="cell">
                            <b>{{ row.code }}</b>
                          </div>
                          <div class="cell-2">
                            <b>{{ row.display_name }}</b>
                          </div>
                          <div class="cell">
                            <b>{{ row.headtype?.name }}</b>
                          </div>
                          <div class="cell-small"></div>
                          <div class="cell">
                            <b>{{ row.headbranch?.name }}</b>
                          </div>
                          <div class="cell">
                            <add-coa-sub-type
                              :account_types="account_types"
                              :branches="branches"
                              :coa_head="row"
                              @coa-sub-head-saved="loadPageOptions"
                            ></add-coa-sub-type>
                            <b></b>
                          </div>
                        </div>
                        <div
                          class="row"
                          v-for="(subhead, index1) in row.subheads"
                          :key="index1"
                        >
                          <div class="cell">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                              subhead.code
                            }}
                          </div>
                          <div class="cell-2">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                              subhead?.name
                            }}
                          </div>
                          <div class="cell">{{ row.headtype?.name }}</div>
                          <div class="cell">{{
                              subhead.amount
                            }} </div>
                          <div class="cell">{{ subhead.coabranch?.name }}</div>
                          <div class="cell">
                            <!-- <transfer-coa-subhead
                              :subhead="subhead"
                              :account_types="account_types"
                              :branches="branches"
                              :coa_head="row"
                              :coa_heads="coa_heads"
                              @coa-sub-head-saved="loadPageOptions">
                            </transfer-coa-subhead> -->
                            <edit-coa-sub-type
                              :subhead="subhead"
                              :account_types="account_types"
                              :branches="branches"
                              :coa_head="row"
                              :coa_heads="coa_heads"
                              @coa-sub-head-saved="loadPageOptions">
                            </edit-coa-sub-type>
                            <!-- <b-button class="btn btn-sm akkurate-green-btn" variant="primary">
                            <span class="akkurate-small"> <font-awesome-icon class="text-lg" :icon="['fas', 'edit']" />Add Member</span>
                          </b-button> -->
                            <!-- <button type="button" class="btn btn-primary btn-sm"><font-awesome-icon class="text-lg" :icon="['fas', 'edit']" /></button> -->
                            </div>
                        </div>
                      </div>
                      <!-- <div v-for="coa_head in coa_heads" :key="coa_head.id"> -->
                      <!-- <tbody>
                        <tr v-for="coa_head in coa_heads" :key="coa_head.id">
                          <td>
                            <b>{{ coa_head.code }}</b>
                          </td>
                          <td>
                            <b>{{ coa_head?.name }}</b>
                          </td>
                          <td>
                            <b
                              >{{ (coa_head.headtype?.name) }} -
                              HEADING</b
                            >
                          </td>
                          <td></td>
                          <td>
                            <b>{{ coa_head.headbranch?.name }}</b>
                          </td>
                          <td>
                            <add-coa-sub-type
                              :account_types="account_types"
                              :branches="branches"
                              :coa_head="coa_head"
                            ></add-coa-sub-type>
                          </td>
                        </tr> -->
                      <!-- <tr
                          v-for="subhead in coa_head.subheads"
                          :key="subhead.id"
                        >
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                              subhead.code
                            }}
                          </td>
                          <td>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                              subhead?.name
                            }}
                          </td>
                          <td>{{ coa_head.headtype?.name }} - Posting</td>
                          <td></td>
                          <td>{{ coa_head.headbranch?.name }}</td>
                          <td></td>
                        </tr> -->

                      <!-- </div> -->
                      <!-- </tbody> -->
                      <!-- <tfoot>
                        <tr>
                          <th class="">Number</th>
                          <th class="">Name</th>
                          <th class="">Type</th>
                          <th class="">Balance</th>
                          <th class="">Branch</th>
                          <th class="">Action</th>
                        </tr>
                      </tfoot> -->
                      <!-- </table> -->
                    </div>
                    <div class="pagination">
                      <button
                      class="btn btn-success"
                        @click="goToPage(currentPage - 1)"
                        :disabled="currentPage === 1"
                      >
                        Previous
                      </button>
                      <span class="">Page {{ currentPage }} of {{ totalPages }}</span>
                      <button
                      class="btn btn-success"
                      
                        @click="goToPage(currentPage + 1)"
                        :disabled="currentPage === totalPages"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer></footer>
    </div>
    <a class="d-inline scroll-to-top" href="#page-top"
      ><i class="fas fa-angle-up"></i
    ></a>
  </div>
</template>


<script>
import ApiService from "@/core/services/api.service";
import Multiselect from "vue-multiselect";
import SidebarView from "@/views/main/components/Sidebar.vue";
import TopbarView from "@/views/main/components/Topbar.vue";
import Footer from "@/views/main/components/Footer.vue";
import AddAccountType from "./../../account-types/forms/AddAccountType.vue";
import AddCoaHead from "./../AddCoaHead.vue";
import AddCoaSubType from "./../AddCoaSubType.vue";
import TransferCoaSubhead from "../TransferCoaSubhead.vue";
import EditCoaSubType from '../EditCoaSubType.vue'
export default {
  components: {
    Multiselect,
    SidebarView,
    TopbarView,
    Footer,
    AddAccountType,
    AddCoaHead,
    AddCoaSubType,
    TransferCoaSubhead,
    EditCoaSubType
  },
  mounted() {
    this.loadPageOptions();
  },
  //   props:["coa_head"],
  data() {
    return {
      isLoading: false,
      form: {
        coa_head: this.coa_head,
        account_number: "",
      },
      search_form: {
        selected_branches: [],
        categories: [],

      },
      add_or_edit: "add",
      branches: [],
      categories: ['STANDARD','PRODUCT', 'USER'],
      account_types: [],
      coa_heads: [],
      currentPage: 1,
      rowsPerPage: 5, 
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.coa_heads.length / this.rowsPerPage);
    },
    displayedRows() {
      const startIndex = (this.currentPage - 1) * this.rowsPerPage;
      const endIndex = startIndex + this.rowsPerPage;
      return this.coa_heads.slice(startIndex, endIndex);
    },
  },
  methods: {
    openCOASubTypeModal() {
      $("#" + this.coa_head.id).modal("show");
    },
    coaHeadSaved() {
      // this.onChangePage(1);
      this.loadPageOptions()
    },
    loadPageOptions() {
      this.$Progress.start();
      this.isLoading = true;
      this.currentPage = 1;
      ApiService.post("/finance/chart-of-accounts/dropdown", this.search_form)
        .then((response) => {
          //   this.account_types = response.data.account_types;
          this.account_types = response.data.account_types;
          this.coa_heads = response.data.coa_heads;
          this.branches = response.data.branches;
          this.isLoading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.$Progress.fail();
        });
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
  },
};
</script>
<style >
.custom-table {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.row {
  display: flex;
  border-bottom: 1px solid #ccc;
}

.row.header {
  font-weight: bold;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  background-color: #f2f2f2; /* Gray background color */
  
}

.cell {
  flex: 2;
  padding: 8px;
}
.cell-small {
  flex: 1;
  padding: 8px;
}
.cell-2 {
  flex: 4;
  padding: 8px;
}

/* Optional styles to make it look more like a table */
.custom-table {
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}

.row:last-child {
  border-bottom: none;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination button {
  margin: 0 5px;
}
</style>