<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <label class="akkurate-dark small" for="name">Name <star></star></label>
      <b-form-group id="name" label-for="name" class="akkurate-dark">
        <b-form-input v-model="form.name" id="name" class="mb-3 mt-2 field-container fields" type="text"
          placeholder="Enter Name" required>
        </b-form-input>
        <errors v-if="form.errors.name">
          {{ form.errors.name[0] }}
        </errors>
      </b-form-group>
    </div>

    <div class="col-12 col-md-6">
      <label class="akkurate-dark small" for="interest_rate">Interest Rate %<star></star></label>
      <b-form-group id="interest_rate" label-for="interest_rate" class="akkurate-dark">
        <b-form-input v-model="form.interest_rate" id="interest_rate" class="mb-3 mt-2 field-container fields"
          type="text" placeholder="Enter Interest Rate" step="any">
        </b-form-input>
        <errors v-if="form.errors.interest_rate">
          {{ form.errors.interest_rate[0] }}
        </errors>
      </b-form-group>
    </div>

    <div class="col-12 col-md-6">
      <label class="akkurate-dark small" for="tenor">Tenure (Days)<star></star></label>
      <b-form-group id="tenor" label-for="tenor" class="akkurate-dark">
        <b-form-input id="tenor" v-model="form.tenor" class="mb-3 mt-2 field-container fields" type="text"
          placeholder="Enter Tenure" step="any" required>
        </b-form-input>
        <errors v-if="form.errors.tenor">
          {{ form.errors.tenor[0] }}
        </errors>
      </b-form-group>
    </div>
    <div class="col-12 col-md-6">
      <label class="akkurate-dark small" for="branches">Branch <star></star></label>
      <b-form-group id="branches" label-for="branches" class="akkurate-dark">
        <multiselect class="field-container mt-2" v-model="form.branch" :options="branch_options"
          placeholder="Select Branches" track-by="name" label="name">
        </multiselect>
        <errors v-if="form.errors.branch_id">
          {{ form.errors.branch_id[0] }}
        </errors>
      </b-form-group>
    </div>

    <div class="col-12 col-md-6">
      <label class="akkurate-dark small" for="fixed_deposit_approval_level">Fixed Deposit Approval Levels <star></star>
      </label>
      <b-form-group id="fixed_deposit_approval_level" label-for="fixed_deposit_approval_level" class="akkurate-dark">
        <multiselect v-model="form.fixed_deposit_approval_levels" :options="approval_options"
          placeholder="Select Fixed deposit Approval Level(s)" track-by="id" :limit-text="limitText" :multiple="true"
          label="name" class="field-container mt-2">
          <template #selection="{ values, search, isOpen }">
            <span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ values.length }} options
              selected</span>
          </template>
        </multiselect>
        <errors v-if="form.errors.fixed_deposit_approval_levels">
          {{ form.errors.fixed_deposit_approval_levels[0] }}
        </errors>
        <div class="mt-1 small" v-html="getApprovals"></div>
      </b-form-group>

    </div>
    <!-- <pre class="language-json"><code></code></pre> -->

  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect
  },
  props: ["form", "add_or_edit", "approval_options", "branch_options"],
  methods: {
    limitText(count) {
      return `and ${count} other approval levels`
    },
  },
  computed: {
    getApprovals() {
      if (this.form.fixed_deposit_approval_levels) {
        return this.form.fixed_deposit_approval_levels?.map(index => {
          return `<span class="approval-item">${index.name}</span>`;
        }).join(' ');
      }

    }
  }
}
</script>
<style>
.approval-item {
  background-color: #018673;
  border: 1px solid;
  /* border-radius: 5px; */
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  /* margin-right: 5px; */
}
</style>