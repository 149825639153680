<template>
    <div id="wrapper">
        <SidebarView :memacc="true" :memaccVisible="true" :allMemAcc="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="container-fluid">
                    <div class="card card-shape home-box">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-md-6">
                                    <h6 class="m-0 fw-bold text-green">All Accounts</h6>
                                </div>
                                <div class="col text-end d-flex align-items-center justify-content-end">
                                    <b-button class="btn btn-sm akkurate-green-btn" href="/members/account/create"
                                        variant="primary">
                                        <span class="akkurate-small"> <i class="fas fa-plus"></i> Add Account</span>
                                    </b-button>
                                    <b-link @click="exportToExcel" class="fw-semibold float-end ms-2  akkurate-green">
                                        <font-awesome-icon :icon="['fas', 'file-excel']" /></b-link>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="card card-shape home-box mb-3 border border-success">
                                <div class="card-body py-2">
                                    <account-filter :form='form' :filterTable="filterTable"></account-filter>
                                </div>
                            </div>
                            <div>
                                <vue-good-table styleClass="vgt-table bordered table-dropdown striped" mode="remote"
                                    ref="memberTable" :columns="columns" :rows="rows" :line-numbers="true"
                                    :isLoading.sync="isLoading" :totalRows="totalRecords" :sort-options="{
                                        enabled: false,
                                    }" :pagination-options="{
                                        enabled: true,
                                        perPage: serverParams.perPage,
                                    }" @on-search="onSearch" @on-page-change="onPageChange"
                                    @on-sort-change="onSortChange" @on-column-filter="onColumnFilter"
                                    @on-per-page-change="onPerPageChange">

                                    <template slot="table-row" slot-scope="props">
                                        <span v-if="props.column.field == 'member_fullname'">
                                            <span>{{ props.row?.customer?.first_name }} {{
                                                props.row?.customer?.last_name }}</span>
                                        </span>
                                        <span class="d-flex" v-else-if="props.column.field == 'action'">
                                            <account-view :data="props.row"></account-view>
                                            <a title="Edit" class="btn btn-sm akkurate-primary text-white border-0"
                                                :href="`/members/account/${props.row.account_number}/edit`">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'edit']" /></span>
                                            </a>

                                            <div title="Account Statement" class="dropdown no-arrow">
                                                <a title="Account Statement"
                                                    class="btn btn-sm akkurate-gray-btn text-white dropdown-toggle"
                                                    id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <span class="akkurate-small"><font-awesome-icon
                                                            :icon="['fas', 'book']" /></span>
                                                </a>
                                                <div class="dropdown-menu shadow animated--fade-in"
                                                    aria-labelledby="dropdownMenu">
                                                    <div class="dropdown-header">Statments</div>

                                                    <a class="dropdown-item"
                                                        v-b-modal="String(props.row?.id + 'statement_preview')">Account
                                                        Entries</a>
                                                    <account-entries :data="props.row" :institution="institution"
                                                        :user="user"></account-entries>

                                                    <a class="dropdown-item"
                                                        v-b-modal="String(props.row?.id + 'account_statement')">Account
                                                        Statement</a>
                                                    <account-statement :data="props.row"
                                                        :institution="institution"></account-statement>

                                                </div>
                                            </div>

                                            <a title="Delete" class="btn btn-sm akkurate-danger-btn text-white"
                                                @click="confirmDeleteUser(props.row)">
                                                <span class="akkurate-small"><font-awesome-icon
                                                        :icon="['fas', 'trash']" /></span>
                                            </a>
                                            <!-- <a title="Account Statement" class="btn btn-sm akkurate-gray-btn text-white" v-b-modal="String(props.row?.id + 'account_statement')">
                                                    <span class="akkurate-small"><font-awesome-icon :icon="['fas', 'book']" /></span>
                                                </a> -->
                                            <!-- <account-statement :data="props.row" :institution="institution"></account-statement> -->
                                        </span>
                                        <span v-else>
                                            {{ props.formattedRow[props.column.field] }}
                                        </span>
                                    </template>
                                </vue-good-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>
<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import AccountFilter from '../forms/AccountFilter.vue'
import ApiService from "@/core/services/api.service";
import AccountStatement from '../statement/AccountStatement.vue';
import AccountEntries from '../entries/AccountEntries.vue';
import AccountView from '../view/AccountView.vue'
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        AccountFilter,
        AccountStatement,
        AccountEntries,
        AccountView
    },
    data() {
        return {
            ...this.initialState(),
            totalRecords: 0,
            searchFilter: "",
            isLoading: false,
            isSaving: false,
            add_or_edit: 'add',
            institution: "",
            user: "",
            columns: [
                {

                    label: 'ID',
                    field: 'id',
                },

                {
                    label: 'Account type',
                    field: 'account_type.name',
                    width: '180px'
                },
                {
                    label: 'Account Category',
                    field: 'account_category',
                    width: '180px'
                },
                {
                    label: 'Balance (GHS)',
                    field: 'account_balance',
                    width: '150px'
                },
                {
                    label: 'Account number',
                    field: 'account_number',
                    width: '180px'
                },
                {
                    label: 'Member Name',
                    field: 'member_fullname',
                    width: '180px'
                },

                {
                    label: 'Member Ext. Code',
                    field: 'customer.external_code',
                    width: '180px'
                },
                {
                    label: 'Member Mobile',
                    field: 'customer.mobile',
                    width: '150px'
                },
                {
                    label: 'Status',
                    field: 'status',
                    type: 'string',
                },
                {
                    label: 'Created',
                    field: 'created_at',
                    type: 'string',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            serverParams: {
                // a map of column filters example: {name: 'john', age: '20'}
                columnFilters: {
                },
                sort: [
                    {
                        field: '', // example: 'name'
                        type: '' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 20 // how many items I'm showing per page
            },
        }
    },
    mounted() {
        this.getAccountDropdown();
        // this.loadItems();
        this.getInsistution();
    },
    methods: {
        async getAccountDropdown() {
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.get("/accounts/create/dropdown")
                .then((response) => {
                    // console.log(response);
                    this.form.branch_option = response?.data?.branches
                    this.form.acc_types_option = response?.data?.account_types
                    this.user = response?.data?.user
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                    // console.log(error);
                })
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params.sortType,
                    field: this.columns[params.columnIndex].field,
                }],
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },
        onSearch(event) {
            // console.log(event)
            this.loadItems(event.searchTerm)
        },
        filterTable() {
            this.serverParams.page = 1;
            this.loadItems();
        },
        async loadItems(searchFilter = null) {
            var branch_id = "";
            var acc_id = "";
            if (this.form.branch != null) {
                branch_id = this.form.branch.id;
            }
            if (this.form.acc_types != null) {
                acc_id = this.form.acc_types.id;
            }
            this.$Progress.start();
            this.isLoading = true;
            await ApiService.post("/accounts/paginate",
                {
                    "page": this.serverParams.page,
                    "per_page": this.serverParams.perPage,
                    "filter": searchFilter,
                    "branch_id": branch_id,
                    "account_type_id": acc_id,
                    "account_number": this.form.account_number,
                    "phone_number": this.form.phone,
                    "start_date": this.form.start_date,
                    "end_date": this.form.end_date,
                    "status": this.form.status //Inactive,	Active, Dormant, Closed
                }).then((response) => {
                    this.rows = response.data.data;
                    this.totalRecords = response.data.meta.total;
                    this.isLoading = false;
                    this.$Progress.finish();
                }).catch((error) => {
                    console.log(error);
                })
        },
        exportToExcel() {
            const workbook = XLSX.utils.book_new();

            // Get the table data
            // const tableData = this.$refs.memberTable.filteredRows[0].children;
            const tableData = this.$refs.memberTable.filteredRows[0].children.map((index) => {
                return {
                    account_type: index.account_type?.name,
                    account_balance: index.account_balance,
                    account_number: index.account_number,
                    member_name: index.customer.first_name + '' + index.customer.last_name,
                    mobile: index.customer.mobile,
                    status: index.status,
                    created_at: index.created_at,
                }
            });
            // console.log(tableData);

            // Convert the table data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(tableData);

            // Add the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

            // Generate the Excel file buffer
            const excelBuffer = XLSX.write(workbook, {
                type: 'array',
                bookType: 'xlsx'
            });

            // Save the Excel file
            FileSaver.saveAs(
                new Blob([excelBuffer], { type: 'application/octet-stream' }),
                'table_data.xlsx'
            );
        },

        initialState() {
            return {
                form: {
                    branch: {},
                    branch_option: [],
                    acc_types: "",
                    acc_types_option: [],
                    phone: "",
                    account_number: "",
                    start_date: "",
                    end_date: "",
                    status: "",
                    status_option: ['ACTIVE', 'INACTIVE', 'DORMANT', 'CLOSED'],
                },

            };
        },
    }
}

</script>
