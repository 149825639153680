<template>
    <div id="wrapper">
        <SidebarView :fixed_deposit="true" :operation="true" :operations-visible="true" />
        <div class="d-flex flex-column" id="content-wrapper">
            <div id="content">
                <TopbarView />
                <div class="card card-shape home-box mx-2">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h6 class="m-0 fw-bold text-green">Fixed Deposit Terms</h6>
                            </div>
                            <div class="col text-end d-flex align-items-center justify-content-end">
                                <create-fixed-deposit @saveFD="saveFD"></create-fixed-deposit>
                                <b-link @click="exportToExcel" class="fw-semibold ms-2 akkurate-green">
                                    <font-awesome-icon :icon="['fas', 'file-excel']" /></b-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body"
                        style="background-color: #eaeff2; border-bottom: 1px solid rgb(1, 134, 115);">
                        <div class="card card-shape home-box mb-3 border border-success">
                            <div class="card-body py-2 mb-3">
                                <basic-search :filter="filter" :basicSearch="basicSearch"
                                    :action="action"></basic-search>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
                                @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange"
                                :totalRows="totalRecords" :isLoading.sync="isLoading" :pagination-options="{
                                    enabled: true,
                                    perPage: serverParams.perPage,
                                }" :columns="columns" :rows="rows">
                                <!-- Add your custom delete button column -->
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field == 'duration'">
                                        <div class="akkurate-dark">{{ calculateDuration(new
                                            Date(props.row.expected_start_date), new Date(props.row.maturity_date)) }}
                                        </div>
                                    </span>
                                    <span class="d-flex" v-else-if="props.column.field == 'action'">
                                        <!-- View Action -->
                                        <a title="View" class="btn btn-sm akkurate-warning text-white border-0"
                                            @click="onFixedDeposit(props.row, 'view')">
                                            <span class="akkurate-small">
                                                <font-awesome-icon :icon="['fas', 'eye']" />
                                            </span>
                                        </a>
                                        <!-- delete -->
                                        <a title="Delete" class="btn btn-sm akkurate-red-btn text-end text-white"
                                            v-if="(props.row?.fixed_deposit?.status?.toUpperCase() == 'PENDING' || props.row?.fixed_deposit?.status?.toUpperCase() == 'REJECTED')"
                                            @click="deleteLoan(props.row)" variant="primary">
                                            <span class="akkurate-small"> <i class="fas fa-trash"></i></span>
                                        </a>
                                        <!-- View Action -->
                                        <!-- <div class="dropdown no-arrow d-inline">
                                            <a class="dropdown-toggle" href="#" role="button" id="dropdownMenu"
                                                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                                                    </a>
                                            <a title="Print" class="btn btn-sm akkurate-ash text-white dropdown-toggle"
                                                id="dropdownMenu" data-bs-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <span class="akkurate-small">
                                                    <font-awesome-icon :icon="['fas', 'book']" />
                                                </span>
                                            </a>
                                            <div class="dropdown-menu shadow animated--fade-in"
                                                aria-labelledby="dropdownMenu">
                                                <div class="dropdown-header">Reports</div>
                                                <advice :data="props.row" :institution="institution"></advice>
                                            </div>
                                        </div> -->
                                        <!-- <a title="Approve" class="btn btn-sm akkurate-green-btn text-white">
                                                      <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'circle-check']" /></span>
                                                </a> -->
                                        <!-- <approval :data="props.row"></approval> -->
                                        <!-- <reedemed :data="props.row"></reedemed> -->
                                    </span>
                                    <span v-else-if="props.column?.field == 'customer_full_name'">
                                        <span>{{ props.row?.customer?.first_name }} {{ props.row?.customer?.last_name
                                            }}</span>
                                    </span>
                                    <span v-else-if="props.column.field == 'principal'">
                                        <span>{{ displayNumber(props.row.fixed_deposit.principal_amount) }}</span>
                                    </span>
                                    <span v-else-if="props.column.field == 'interest_expected'">
                                        <span>{{ displayNumber(props.row.fixed_deposit.interest_expected) }}</span>
                                    </span>
                                    <span v-else-if="props.column.field == 'maturity_value'">
                                        <span>{{ displayNumber(props.row.fixed_deposit.maturity_value) }}</span>
                                    </span>
                                </template>
                            </vue-good-table>
                            <!-- Modal: FD View (Unique for Each Row) -->
                            <b-modal :id="'fd-modal-' + currentFDId" size="xxl" @shown="showView">
                                <template #modal-title>
                                    <h6 class="m-0 fw-bold text-green">Fixed Deposit View</h6>
                                </template>

                                <!-- Fixed Deposit View Component -->
                                <fixed-view :data="currentFdData" :institution="institution" :user_role="user_role"
                                    :current_user_id="current_user_id" @approvedFD="approvedFD"></fixed-view>

                                <template #modal-footer>
                                    <div class="w-100">
                                        <b-button @click="$bvModal.hide('fd-modal-' + currentFDId)" type="submit"
                                            class="akkurate-gray-btn btn-size float-right">
                                            <font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close
                                        </b-button>
                                    </div>
                                </template>
                            </b-modal>
                        </div>
                    </div>
                </div>
            </div>
            <footer></footer>
        </div>
        <a class="d-inline scroll-to-top" href="#page-top"><i class="fas fa-angle-up"></i></a>
    </div>
</template>

<script>
import SidebarView from '@/views/main/components/Sidebar.vue';
import TopbarView from '@/views/main/components/Topbar.vue';
import Footer from '@/views/main/components/Footer.vue';
import BasicSearch from './form/BasicSearch.vue'
import Search from './form/Search.vue'
import AdvanceSearch from './form/AdvanceSearch.vue';
import ApiService from '@/core/services/api.service';
import Reedemed from './form/modal/Reedemed.vue'
import Approval from './form/modal/Approval.vue'
import Advice from './reports/Advice.vue'
import CreateFixedDeposit from './form/modal/create/CreateFixedDeposit.vue';
import FixedView from './form/modal/view/FixedView.vue';
export default {
    components: {
        SidebarView,
        TopbarView,
        Footer,
        BasicSearch,
        Search,
        AdvanceSearch,
        Reedemed,
        Approval,
        Advice,
        CreateFixedDeposit,
        FixedView
        // DepositForm
    },
    created() {
        this.loadItems();
        this.getInsistution();
    },
    data() {
        return {
            form: {
                payment: "Cash",
            },
            institution: "",
            filter: {
                code: "",
                search: "",
                status: "",
                from_date: "",
                to_date: "",
                branch: "",
                trans_source: "",
                branch_option: [],
            },
            user_role: "",
            current_user_id: "",
            currentFdData: "",
            advSearch: {},
            action: {
                search_btn: "Search",
                disabled_search: false,
            },
            columns: [
                {
                    label: 'FD #',
                    field: 'fixed_deposit.code',
                    width: '100px'
                },
                {
                    label: 'Scheme',
                    field: 'fixed_deposit.fixed_deposit_product.name',
                },
                {
                    label: 'Member Name',
                    field: 'customer_full_name',
                    width: '150px'
                },
                {
                    label: 'Principal',
                    field: 'principal',
                    type: 'string',
                },
                {
                    label: 'Expected Interest',
                    field: 'interest_expected',
                    type: 'string',
                    width: '150px'
                },
                {
                    label: 'Duration',
                    field: 'fixed_deposit.current_duration',
                    type: 'string',
                    width: '150px'
                },
                {
                    label: 'Maturity.VAL',
                    field: 'maturity_value',
                    type: 'string',
                    width: '150px'
                },
                {
                    label: 'Maturity Date',
                    field: 'fixed_deposit.maturity_date',
                    type: 'string',
                    width: '150px'
                },
                {
                    label: 'Rate',
                    field: 'fixed_deposit.interest_rate',
                    type: 'string',
                },
                {
                    label: 'Start Date',
                    field: 'fixed_deposit.expected_start_date',
                    type: 'string',
                    width: '180px'
                },
                {
                    label: 'Status',
                    field: 'fixed_deposit.status',
                    type: 'string',
                    width: '150px'
                },
                {
                    label: 'Mandate',
                    field: 'fixed_deposit.fixed_deposit_mandate',
                    type: 'string',
                    width: '150px'
                },
                {
                    label: 'Daily interest',
                    field: 'fixed_deposit.daily_interest',
                    type: 'string',
                    width: '150px'
                },
                {
                    label: 'Interest due',
                    field: 'fixed_deposit.interest_due',
                    type: 'string',
                    width: '150px'
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            rows: [],
            isLoading: false,
            totalRecords: 0,
            currentFDId: "",
            serverParams: {
                columnFilters: {
                },
                sort: [
                    {
                        field: '',
                        type: ''
                    }
                ],
                page: 1,
                perPage: 20
            }

        }
    },
    methods: {
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },

        onSortChange(params) {
            this.updateParams({
                sort: [{
                    type: params.sortType,
                    field: this.columns[params.columnIndex].field,
                }],
            });
            this.loadItems();
        },

        onColumnFilter(params) {
            this.updateParams(params);
            this.loadItems();
        },

        // load items is what brings back the rows from server
        async loadItems() {
            await ApiService.query('/investments/fixed-term-deposits', {
                params: {
                    "page": this.serverParams.page,
                    "per_page": this.serverParams.perPage,
                    "branch_ids": this.advSearch.branch, //ie fixed deposit number
                    "start_date": this.advSearch.start_date,
                    "end_date": this.advSearch.end_date,
                    "status": this.advSearch.status, // "COMPLETED", "PENDING", "FAILED"
                    "customer_code": this.advSearch.customer_code
                }
            }).then((response) => {
                console.log(response.data.data);
                this.rows = response.data.data;
            }).catch((error) => {
                console.log(error);
            });
        },
        basicSearch() {
            this.getSingleFD();
        },
        getSingleFD() {
            this.action.search_btn = "Processing...."
            this.action.disabled_search = true;
            ApiService.get(`/investments/fixed-term-deposits/${this.filter.code}`)
                .then((response) => {
                    // console.log(response.data.data);
                    let payment = this.form.payment;
                    this.form = response.data.data
                    let first_name = this.form?.customer?.first_name != null ? this.form?.customer?.first_name : "";
                    let last_name = this.form?.customer?.last_name != null ? this.form?.customer?.last_name : "";
                    this.form.customer_name = first_name + ' ' + last_name;
                    this.form.payment = payment;
                    this.action.search_btn = "Search"
                    this.action.disabled_search = false;
                }).catch((error) => {
                    this.action.search_btn = "Search"
                    this.action.disabled_search = false;
                    console.log(error);
                })
        },
        advanceSearch(data) {
            this.advSearch = data;
            // console.log(this.advSearch);
            this.loadItems();
        },
        async getInsistution() {
            await ApiService.get('/settings/institution').then((response) => {
                // console.log(response);
                this.institution = response.data.data;
            }).catch((error) => {
                // console.log(error);
            })
        },
        calculateDuration(startDate, endDate) {
            // Convert both dates to milliseconds
            const startMillis = startDate.getTime();
            const endMillis = endDate.getTime();

            // Calculate the difference in milliseconds
            let durationMillis = Math.abs(endMillis - startMillis);

            // Calculate the duration in days, hours, minutes, and seconds
            const millisecondsInDay = 1000 * 60 * 60 * 24;
            const days = Math.floor(durationMillis / millisecondsInDay);
            durationMillis %= millisecondsInDay;
            // const hours = Math.floor(durationMillis / (1000 * 60 * 60));
            // durationMillis %= (1000 * 60 * 60);
            // const minutes = Math.floor(durationMillis / (1000 * 60));
            // durationMillis %= (1000 * 60);
            // const seconds = Math.floor(durationMillis / 1000);

            // Return an object with the duration components
            return days;
            // return {
            //     days: days,
            //     hours: hours,
            //     minutes: minutes,
            //     seconds: seconds
            // };
        },
        approvedFD(){
            this.loadItems();
        },
        exportToExcel() {

        },
        saveFD() {
            this.loadItems();
        },
        onFixedDeposit(row, type) {
            this.currentFDId = row.id; // Set the current FD ID
            this.currentFdData = row; // Set the data of the selected FD to be displayed in the modal
            // Ensure this is done immediately
            // Set the active tab based on the 'type' parameter
            console.log(type)
            console.log(this.currentFdData);
            // if (type === 'view') {
            //     this.term_active_menu = true;
            // }
            this.$nextTick(() => {
                this.$bvModal.show('fd-modal-' + this.currentFDId);
            });
        },
        showView() {
            // Optional: Additional logic when the modal is shown
            // console.log("FD modal is shown for ID:", this.currentFdData);
            this.user_role = localStorage.getItem("user_role")
            this.current_user_id = localStorage.getItem("userId")
            console.log(this.current_user_id)
        },
        deleteLoan(data) {

            swal.fire({
                title: "Delete Fixed Deposit",
                text: 'Are you sure you want to delete fixed deposit ' + data.fixed_deposit.code + ' ?',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "YES",
                allowOutsideClick: true
            }).then(result => {
                if (result.value) {
                    this.$Progress.start();
                    ApiService.delete('/investments/fixed-term-deposits/' + data.fixed_deposit.id)
                        .then((response) => {
                            this.$Progress.finish();
                            this.loadItems();
                            // this.$bvToast.toast('Loan Deleted Successfully', {
                            //     title: 'Success',
                            //     variant: 'success',
                            //     solid: true
                            // })
                            swal.fire({
                                // title: response.statusText,
                                text: response.data.message,
                                icon: "success",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                            this.loadItems();
                        }).catch((error) => {
                            this.$Progress.fail();

                            swal.fire({
                                // title: response.statusText,
                                text: error.response.data.message,
                                icon: "error",
                                allowOutsideClick: true,
                                allowEscapeKey: true,
                                showCancelButton: false, // Show the "Cancel" button
                                confirmButtonText: 'okay', // Customize the text for the "OK" button
                                buttonsStyling: false, // Disable SweetAlert2 styling for buttons
                                customClass: {
                                    confirmButton: 'btn akkurate-green-btn modal-btn-width text-white me-2', // Custom class for the "OK" button
                                }
                            });
                        })
                }
            });

        }

    }

}

</script>