<template>
    <div>
        <a title="View" class="btn btn-sm akkurate-warning text-white broder-0" v-b-modal="String(data.id + 'view')" >
        <span class="akkurate-small"> <font-awesome-icon :icon="['fas', 'eye']" /></span> 
    </a>
    <b-modal :id="String(data.id + 'view')" size="xl" @show="showView">
            <template #modal-title>
                <p class="m-0 fw-bold text-green">Account View</p>
            </template>
            <div class="container">
                <!-- <beat-loader-component
                v-if="isLoading"
                :isLoading="isLoading"
            ></beat-loader-component> -->
                <div class="row">
                     <div class="card px-0 mb-3">
                        <div class="card-header py-3">
                            <h6 class="m-0 fw-bold text-green">Profile Preview</h6>
                        </div>
                        <div class="card-body akkurate-card-light-bg">
                           <div class="row">
                                <div class="col-md-3 col-12 text-center">
                                    <img src="@/assets/img/user-avater.jpg" style="height:200px" class="img-fluid" alt="user-profile"/>
                                    <p class="mt-2 akkurate-green fw-bold">Preview</p>
                                </div>
                                <div class="col-md-3 col-12 text-center">
                                        <img
                                            v-if="imageLoaded"
                                            v-bind:src="frontImgUrl"
                                            alt="Image"
                                            @load="handleImageLoad"
                                            style="height:200px" 
                                            class="img-fluid text-center"
                                            />
                                        <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image" style="height:200px" class="img-fluid text-center" />
                                        <p class="mt-2 akkurate-green fw-bold">ID Card - Front</p>
                                </div>
                                <div class="col-md-3 col-12 text-center">
                                    <img
                                        v-if="imageLoaded"
                                        v-bind:src="backImgUrl"
                                        alt="Image"
                                        @load="handleImageLoad"
                                        style="height:200px" 
                                        class="img-fluid text-center"
                                    />
                                    <img v-else src="@/assets/img/Ghana-Card-Front.png" alt="Default Image" style="height:200px" class="img-fluid text-center" />
                                    <p class="mt-2 akkurate-green fw-bold">ID Card - Back</p>
                                </div> 
                                <div class="col-md-3 col-12 akkurate-green">
                                    <h6 class="mb-0 fw-bold">Customer Name</h6>
                                    <p class="mb-2">{{ form.customer?.title }} {{ getFullName }}</p>
                                    <h6 class="mb-0 fw-bold">Mobile</h6>
                                    <p class="mb-2">{{ form.customer?.mobile }}</p>
                                    <h6 class="mb-0 fw-bold">Email</h6>
                                    <p class="mb-2">{{ form.customer?.email }}</p>
                                    <h6 class="mb-0 fw-bold">Address</h6>
                                    <p class="mb-2">{{ form.customer?.address }}</p>
                                    <div class="d-flex">
                                        <!-- <p class="fw-bold">TEL: <span class="fw-light me-3"> {{ form.customer?.mobile  }}</span></p> -->
                                        <!-- <p class="fw-bold">STAFF ID: <span class="fw-light">9858958</span></p> -->
                                    </div>
                                </div> 
                           </div>
                        </div>
                        <hr class="m-0"/>
                        <div class="card-header py-3">
                            <h6 class="m-0 fw-bold text-green">Account Details</h6>
                        </div>
                        <div class="card-body">
                            <div class="row"> 
                            <!-- <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="customer_name">Customer Name</label>
                                <b-form-group
                                id="customer_name"
                                label-for="customer_name">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        placeholder="Enter Account Name"
                                        v-model="getFullName"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div> -->
                            <!-- <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="mobile">Mobile</label>
                                <b-form-group
                                id="mobile"
                                label-for="mobile">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        placeholder="Enter Mobile"
                                        v-model="getMobile"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div> -->
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="account_number">Account Number</label>
                                <b-form-group
                                id="account_number"
                                label-for="account_number">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        placeholder="Enter Account Balance"
                                        v-model="data.account_number"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="account_balance">Account Balance</label>
                                <b-form-group
                                id="account_balance"
                                label-for="account_balance">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        placeholder="Enter Account Balance"
                                        v-model="data.account_balance"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="account_name">Account Type</label>
                                <b-form-group
                                id="account_name"
                                label-for="account_name">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        placeholder="Enter Account Name"
                                        v-model="getAccountTypeName"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                        
                        
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="branch">Branch</label>
                                <b-form-group
                                id="branch"
                                label-for="branch">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        placeholder="Enter Branch Name"
                                        v-model="getBranchName"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="status">Status</label>
                                <b-form-group
                                id="status"
                                label-for="status">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        placeholder="Enter Status"
                                        v-model="data.status"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="status">Withdrawal limit</label>
                                <b-form-group
                                id="withdrawal_limit"
                                label-for="withdrawal_limit">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.withdrawal_limit"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="maximum_withdrawal_frequency">Max Withdrawal Frequency</label>
                                <b-form-group
                                id="maximum_withdrawal_frequency"
                                label-for="maximum_withdrawal_frequency">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.maximum_withdrawal_frequency"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="auto_transfer">Auto Transfer</label>
                                <b-form-group
                                id="auto_transfer"
                                label-for="auto_transfer">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.auto_transfer"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="interest_frequency">Interest frequency</label>
                                <b-form-group
                                id="interest_frequency"
                                label-for="interest_frequency">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.interest_frequency"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="commission">Commission</label>
                                <b-form-group
                                id="commission"
                                label-for="commission">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.commission"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="apply_commission">Commission</label>
                                <b-form-group
                                id="apply_commission"
                                label-for="apply_commission">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.apply_commission"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="interest_rate">Interest rate</label>
                                <b-form-group
                                id="interest_rate"
                                label-for="interest_rate">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.interest_rate"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="account_interest_start_date">Interest start date</label>
                                <b-form-group
                                id="account_interest_start_date"
                                label-for="account_interest_start_date">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.account_interest_start_date"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-4">
                                <label class="akkurate-dark" for="account_commission_start_date">Commission start date</label>
                                <b-form-group
                                id="account_commission_start_date"
                                label-for="account_commission_start_date">
                                    <b-form-input
                                        class="mb-3 mt-2 field-container fields"
                                        type="text"
                                        v-model="data.account_commission_start_date"
                                        disabled
                                        required
                                    ></b-form-input>
                                </b-form-group>
                            </div>

                        </div>

                        </div>
                     </div>
                    
                </div>
               

            </div>
            
            

            <template #modal-footer>
                    <div class="w-100">
                    <!-- begin loader -->
                    <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                    <!-- end loader -->
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-button @click="$bvModal.hide(String(data.id + 'view'))" type="submit" class="akkurate-grey me-2 btn-size  float-right"><font-awesome-icon class="me-2" :icon="['fas', 'close']" />Close</b-button>     
                    </div>
            </template>
        
    </b-modal>
    </div>
     
</template>
<script>
import ApiService from "@/core/services/api.service";
import BeatLoaderComponent from "@/views/components/Loader/BeatLoaderComponent.vue";
// import PictureView from './modal/PictureView.vue'
// import DocumentView from './modal/DocumentView.vue'
export default {
    props: ['data'],
    components: {
        BeatLoaderComponent,
    },
    computed: {
        getFullName() {
            const first_name = this.data.customer.first_name != undefined ? this.data.customer.first_name : "";
            const middle_name = this.data.customer.middle_name != undefined ? this.data.customer.middle_name : "";
            const last_name = this.data.customer.last_name != undefined ? this.data.customer.last_name : "";
            return first_name + ' ' + middle_name + ' ' + last_name;

        },
        getAccountTypeName() {
            return this.data?.account_type?.name;
        },
        getBranchName() {
            return this.data?.branch?.name
        },
        getMobile() {
            let mobile = this.data?.customer?.mobile != undefined ? this.data?.customer?.mobile : "";
            return mobile;
        }
    },
    data() {
        return {
            loan: {
                status: "",
                tenor: "",
                interest_rate: "",
                interest_method: "",
                expected_interest: "",
                interest_balance: "",
                interest_paid: "",
                interest_balance: "",
                loan_number: "",
                principal_amount: "",
                principal_paid: "",
                principal_balance: "",
                principal_due: "",
                loan_product: "",
                account: "",
                start_date: "",
                last_payment: "",
                delinquent: "",
                interest_due: "",
            },
            form: {},
            imageLoaded: false,
            frontImgUrl: "",
            backImgUrl: "",
            isLoading: false,
            isBusy: false,
            col_fields: [
                {
                    key: 'id',
                    label: '#',
                },
                {
                    key: 'loan_number',
                    label: 'Loan Number',
                },
                {
                    key: 'product_name',
                    label: 'Product Name',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
                {
                    key: 'address',
                    label: 'Address',
                },
                {
                    key: 'risk_level',
                    label: 'Risk Level',
                },
                {
                    key: 'action',
                    label: 'Action',
                },
            ],
            col_items: [],
            gua_fields: [
                {
                    key: 'id',
                    label: '#',
                },
                {
                    key: 'loan_number',
                    label: 'Loan Number',
                },
                {
                    key: 'amount',
                    label: 'Amount',
                },
                {
                    key: 'entry_date',
                    label: 'Entry Date',
                },
                {
                    key: 'account.account_number',
                    label: 'Account Number',
                },
                {
                    key: 'name',
                    label: 'Guarantor(s) Name',
                },
            ],
            gua_items: [],
        }
    },

    methods: {
        async getLoanView() {
            this.isLoading = true;
            this.isBusy = true,
                await ApiService.get(`/accounts/view/${this.data.account_number}`)
                    .then((response) => {
                        this.isLoading = false;
                        this.isBusy = false;
                        this.form = response.data.account;
                        this.handleImageLoad();
                        // this.loan = response.data.data;
                        // console.log(response.data.data);
                        // this.col_items = response.data.data.collaterals;
                        // this.gua_items = response.data.data.guarantors;
                    }).catch((error) => {
                        this.isLoading = false;
                        this.isBusy = false;
                        // console.log(error);
                    });
        },
        showView() {
            // console.log(this.data);
            this.getLoanView();
        },
        handleImageLoad() {

            if (this.form.customer?.gh_card_front_path) {
                this.imageLoaded = true;
                this.frontImgUrl = this.form.customer?.gh_card_front_path;
            }
            if (this.form.customer?.gh_card_back_path) {
                this.imageLoaded = true;
                this.backImgUrl = this.form.customer?.gh_card_back_path;
            }


        },
    }
}
</script>
