<template>
    <div class="container-fluid" ref="pdfContent">
        <!-- First Receipt -->
        <div style="border-left: 5px solid #df3f50; border-bottom: 5px solid #df3f50;">
            <institution :institution="institution" :title="'VOCUHER PAYMENT RECEIPT'" :report_style="'withdrawal'">
            </institution>

            <div class="row ps-2">
                <div class="col-md-12 col-12 text-center mt-2">
                    <p class="fw-bold report-l-text">PAYMENT VOUCHER</p>
                </div>
            </div>
            <div class="row ms-0 ps-2">
                <div class="col">
                    <p class="mb-0 report-l-text text-uppercase"> BRANCH: <span class="fw-bold">{{
                        data?.branch.name
                            }}</span></p>
                </div>
                <div class="col text-center">
                    <p class="mb-0 report-l-text"> VOUCHER CODE: <span class="fw-bold">{{
                        data?.code
                            }}</span></p>
                </div>
                <div class="col text-end">
                    <p class="mb-0 report-l-text"> DATE: <span class="fw-bold">{{
                        data?.recorded_date
                            }}</span></p>
                </div>

            </div>
            <hr />
            <div class="row ms-0 ps-2 mt-2">
                <div class="col">
                    <p class="report-l-text">NARRATION: <span class="fw-bold">{{ data.narration }}</span></p>
                </div>
                <div class="col text-end">
                    <p class="report-l-text">CHEQUE NO: <span class="fw-bold">{{ data.cheque_number }}</span></p>
                </div>
            </div>

            <div class="row ms-0 ps-2 mt-2 trans_details">
                <b-table-simple hover small caption-top responsive>
                    <template v-if="isLoading">
                        <!-- Busy state: loading spinner or message -->
                        <b-spinner label="Loading..." variant="primary" size="lg" class="d-block mx-auto"></b-spinner>
                    </template>
                    <template v-else>
                        <b-thead>
                            <b-tr>
                                <b-th>COA code</b-th>
                                <b-th>Name</b-th>
                                <b-th>Type</b-th>
                                <b-th>Dr amount</b-th>
                                <b-th>Cr amount</b-th>
                            </b-tr>
                        </b-thead>
                        <tbody>
                            <b-tr v-for="(value, item) in items" :key="item">
                                <b-td>{{ value.COA_code }}</b-td>
                                <b-td>{{ value.name }}</b-td>
                                <b-td>{{ value.type }}</b-td>
                                <b-td>{{ value.dr_amount }}</b-td>
                                <b-td>{{ value.cr_amount }}</b-td>
                            </b-tr>
                        </tbody>
                        <b-tfoot>
                            <b-tr>
                                <b-th colspan="3">
                                    Total
                                </b-th>
                                <b-th>
                                    {{ getTotalDrAmount }}
                                </b-th>
                                <b-th>
                                    {{ getTotalCrAmount }}
                                </b-th>
                            </b-tr>
                        </b-tfoot>
                    </template>
                </b-table-simple>
            </div>
        </div>
        <div class="row ms-0 ps-2 mt-2">
            <div class="col">
                <div class="akkurate-dark small">Prepared by:</div>
                <div class="akkurate-dark small fw-bold">{{ data.created_by.first_name +' '+ data.created_by.surname}}</div>
            </div>
            <div class="col text-end">
                <div class="akkurate-dark small">Approved by:</div>
                <div class="akkurate-dark small fw-bold">{{ data.created_by.first_name +' '+ data.created_by.surname}}</div>
            </div>
        </div>

        <div class="row ms-0 ps-2 mt-3">
            <div class="col">
                _______________________________
                    <div class="small">Branch Manager</div>
            </div>
            <div class="col text-end">
                _______________________________
                    <div class="small">System Defined</div>
            </div>
        </div>

        <div class="row ms-0 ps-2 mt-3">
            <div class="col">
                <div class="small fw-bold">
                    PAYEE/BENEFICIARY
                </div>
                <div class="small">
                    {{ data.beneficiary_name }}

                </div>
            </div>
        </div>
        <div class="row ms-0 ps-2 mt-3">
            <div class="col">
                <div class="col">
                _______________________________
                    <div class="small">Signature and Date</div>
            </div>
            </div>
        </div>
        
        

    </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import Institution from '@/views/main/components/institution/Institution.vue'
import ApiService from "@/core/services/api.service";
export default {
    components: {
        Institution,
    },
    props: ['data', 'institution'],
    data() {
        return {
            isPrinting: false,
            items: [],
            isLoading: false
        }
    },
    mounted() {
        if (this.data.id) {
            this.getJournalView(this.data.id)
        }
    },
    computed: {
        getTotalDrAmount() {
            return this.items.reduce((sum, item) => sum + item.dr_amount, 0);
        },
        getTotalCrAmount() {
            return this.items.reduce((sum, item) => sum + item.cr_amount, 0);
        }
    },
    methods: {
        getJournalView(id = null) {
            this.isLoading = true;
            ApiService.get(`/vouchers/view/${id}`).then((response) => {
                const account = [];
                // this.form = response.data.data;
                console.log(response.data.data)
                this.items = response.data.data.vouch_journal_coaaccounts.map((index) => {
                    return {
                        'COA_code': index.id,
                        'name': index.coa_sub_head.name,
                        'type': index.coa_sub_head.coatype.name,
                        'dr_amount': index.dr_amount,
                        'cr_amount': index.cr_amount,

                    }
                });
                // console.log(response.data.data.vouch_journal_coaaccounts);
                this.isLoading = false;
            }).catch((error) => {
                this.isLoading = false
                console.log(error);
            })
        },
        exportToPDF() {
            const content = this.$refs.pdfContent; // Reference to the HTML content you want to convert
            // console.log("here");
            const pdfOptions = {
                margin: 10,
                filename: 'generated.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCROS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            };
            html2pdf().from(content).set(pdfOptions).save();
        },
        generatePDF() {
            // Define the element to convert to a PDF
            const element = this.$refs.pdfContent; // Replace with your element's ID or selector
            // console.log(elementToConvert);

            // Define the options for html2pdf
            const pdfOptions = {
                margin: 10,
                filename: 'document.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCROS: true },
                jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
                debug: true, // Enable debugging
            };
            html2pdf().from(element).set(pdfOptions).outputPdf('blob').then((pdf) => {
                const blob = new Blob([pdf], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                // console.log(pdf);
                const printWindow = window.open(url, '_blank', 'width=800,height=600');
                printWindow.onload = () => {
                    printWindow.print();
                    URL.revokeObjectURL(url);
                };
            });
        },

    }

}
</script>