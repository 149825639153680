<template>
    <div>
        <!-- <b-button >Launch demo modal</b-button> -->
        <b-link class="fw-semibold ms-2 akkurate-green">
            <font-awesome-icon :icon="['fas', 'filter']" v-b-modal="type" /></b-link>
        <form @submit.prevent="filterLoan">
            <b-modal :id="type" size="lg" :ref="type">
                <template #modal-title>
                    <p class="akkurate-green fw-bold">Filter</p>
                </template>
                <div class="row">
                    <div class="col">
                        <label class="akkurate-dark small">Start Date</label>
                        <b-form-group id="loan_code" label-for="loan_code">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="date"
                                v-model="adv_filter.start_date"></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col">
                        <label class="akkurate-dark small">End Date</label>
                        <b-form-group id="loan_code" label-for="loan_code">
                            <b-form-input class="mb-3 mt-2 field-container fields" type="date"
                                v-model="adv_filter.end_date"></b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <template #modal-footer>
                    <div class="w-100">
                        <!-- begin loader -->
                        <!-- <beat-loader-component 
                    class="float-left"
                    v-if="isLoading"
                    :isLoading="isLoading"
                    ></beat-loader-component> -->
                        <!-- end loader -->
                        <!-- Emulate built in modal footer ok and cancel button actions -->
                        <b-button @click="filterLoan" :disabled="isSearching"
                            class="akkurate-green-btn btn-size float-right"><font-awesome-icon class="me-2"
                                :icon="['fas', 'search']" />{{ search }}</b-button>
                        <b-button @click="$bvModal.hide(String(type))"
                            class="akkurate-gray-btn btn-size me-2 float-right"><font-awesome-icon class="me-2"
                            :icon="['fas', 'close']" />Close</b-button>
                        
                    </div>
                </template>
            </b-modal>

        </form>

    </div>
</template>
<script>
export default {
    props: ['adv_filter', 'isSearching', 'search', 'type'],
    data() {
        return {

        }
    },
    methods: {
        filterLoan() {
            this.$emit('filterLoan');
            if (this.isSearching != true) {
                this.$refs[this.type].hide()
            }

        }
    }

}
</script>